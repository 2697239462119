import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeTrackingService } from '../../../services/time-tracking.service';

@Component({
  selector: 'app-follow-up-dialog',
  templateUrl: './follow-up-dialog.component.html',
  styleUrls: ['./follow-up-dialog.component.scss']
})
export class FollowUpDialogComponent implements OnInit {

  busy: Subscription;

  @Input() projectId;
  @Input() id;

  phase = null;
  phases;
  followUp;
  issue;
  risk;
  riskMitigation;

  title: string = 'Add Follow Up';

  constructor(private modalClose: NgbActiveModal, private ttService: TimeTrackingService) { }

  ngOnInit() {
    this.followUp = '';
    this.issue = '';
    this.risk = '';
    this.riskMitigation = '';
    this.getPhase();
    if (this.id) {
      this.title = 'Edit Follow Up';
      this.getById();
    }
  }

  getById() {
    this.ttService.getFollowUpById(this.id).subscribe(data => {
      this.riskMitigation = data['riskMitigation'];
      this.projectId = data['project'];
      this.phase = data['phase'];
      this.issue = data['issue'];
      this.risk = data['risk'];
      this.followUp = data['description'];
    });
  }

  close() {
    this.modalClose.close();
  }

  save() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  getPhase() {
    this.busy = this.ttService.getPhasesByProject(this.projectId).subscribe(
      data => {
        this.phases = data['phases'];
      }
    );
  }

  delete() {
    if (confirm('Are you sure you want to delete ' + this.id + '?')) {
      this.ttService.deleteFollowUp(this.id).subscribe(() => {
        alert('Follow up deleted.');
        this.close();
      });
    }
  }

  create() {
    this.busy = this.ttService.createFollowUp(
      {
        'project': this.projectId,
        'user': sessionStorage.getItem('userId'),
        'phase': this.phase,
        'description': this.followUp,
        'issue': this.issue,
        'risk': this.risk,
        'riskMitigation': this.riskMitigation,
      }
    ).subscribe(
      () => {
        alert('Follow up created');
        this.close();
      }
    );
  }

  update() {
    this.busy = this.ttService.updateFollowUp(
      {
        'project': this.projectId,
        'user': sessionStorage.getItem('userId'),
        'phase': this.phase,
        'description': this.followUp,
        'issue': this.issue,
        'risk': this.risk,
        'riskMitigation': this.riskMitigation,
      }, this.id
    ).subscribe(
      () => {
        alert('Follow up updated');
        this.close();
      }
    );
  }
}
