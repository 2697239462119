import { AttachmentsDialogComponent } from './../attachments-dialog/attachments-dialog.component';
import { TimeSheetDialogComponent } from './../timesheet-dialog/timesheet-dialog.component';
import { Subscription } from 'rxjs';
import { Input } from '@angular/core';
import { TimeTrackingService } from './../../../services/time-tracking.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import "bootbox";
import { SystemDialogComponent } from '../system-dialog/system-dialog.component';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'contract-dialog',
  templateUrl: './contract-dialog.component.html'
})
export class ContractDialogComponent implements OnInit {
  @Input() contractId: any;
  @Input() custId: any;
  
  public companyId = sessionStorage.getItem('compId');

  newVersionModalRef:     NgbModalRef;
  logDescModalRef:         NgbModalRef;
  busy:                   Subscription;
  values = new Array();
  
  titleHeader:            string;
  nameSelected:           string;
  descriptionSelected:    string;
  statusSelected:         string;
  cohiDescription:        string = '';
  logTitle:               string;
  dateError:              string;  

  
 
  //comment:                string;
  
  tempContractPeriodFrom:           string[];
  tempContractPeriodTo:           string[];

  disableAll:             boolean;
  buttonShow:             boolean;
  buttonShow2:            boolean;
  showContractHistory:    boolean;
  showButton:             boolean;
  buttonAction:           boolean;
  enableUser:             boolean;
  falseButton:            boolean;
  disableCustomer:        boolean;

  totalPages:             number;
  totalElements:          number;

  fullLog:                any;
  event:                  any;
  contractHistories:      any;
  users:                  any;
  systems:                any;
  customers:              any;
  contract:               any = null;
  author:                 any;
  customerSelected:       any;
  customerSelectedOld:    any;
  customerName:           any;
  customerNameOld:        any;
  minimumAmount:          any = '';
  authorSelected:         any = null;
  authorName:             any = '';
  tempContractPeriodFromTr:     any;
  tempContractPeriodToTr:       any;
  serviceTimeFrom:            string;
  serviceTimeTo:              string;
  contractPeriodFrom:           any;
  contractPeriodTo:             any;
  systCols:               any;
  cohiCols:               any;
  nameSelectedOld:        any;
  minimumAmountOld:       any = '';
  statusSelectedOld:      any;
  descriptionSelectedOld: any = '';
  authorSelectedOld:      any = '';
  authorNameOld:          any = '';
  commentOld:             any;
  serviceTimeFromOld:         string;
  serviceTimeToOld:           string;
  
  historyDate:            Date;

  alertsDismiss:          any[] = [];
  contractSystems:        any[] = [];
  logDescription:         any;
  
  contractPeriodFromOld: { year: number; month: number; day: number; };
  contractPeriodToOld:   { year: number; month: number; day: number; };     
  

  constructor(private ttService: TimeTrackingService, private ngbModal: NgbModal, private modalRef: NgbActiveModal){}
  
  ngOnInit() {

    this.titleHeader = 'New Contract';  

    this.disableAll = false;
    this.disableCustomer = false;
    this.buttonShow = false;
    this.buttonShow2 = false;
    this.showButton = false;
    this.buttonAction = false;
    this.showContractHistory = false;

    
    
    this.alertsDismiss = [];
    this.nameSelected = '';
    this.customerSelected = '';
    this.customerSelectedOld = '';
    this.customerName = '';
    this.customerNameOld = '';
    this.descriptionSelected = '';
    this.authorSelected = '';
    this.statusSelected = "";
    this.contractPeriodFrom = null;
    this.minimumAmount = null;
    this.cohiDescription = '';
    var profile = sessionStorage.getItem('userProfile');
    this.serviceTimeFrom = '';
    this.serviceTimeTo = '';
    
    this.getUsers();
    this.getSystems();
    if(this.custId){
      this.customerSelected = this.custId;
      this.disableCustomer = true;
    }
    this.getCustomers();

    this.systCols = [
      { field: 'id', header: 'Id' },
      { field: 'name', header: 'Name' },
      ];  
      

    if (this.contractId) {

      this.buttonAction = true;
      this.showContractHistory = true;
      this.showButton = true;

      this.getContract(this.contractId);

      if (profile == 'USER') {
        this.enableUser = false;
      }

      this.cohiCols = [
        //{ field: 'id',     header: 'History ID' },
        { field: 'date',       header: 'Date' },
        { field: 'userName',   header: 'User' },
        { field: 'action', header: 'Action' },
        { field: 'description',   header: 'Description' }
        
        ];   
      }  
  }

  getContract(contractId) {
    this.busy = this.ttService.getContract(contractId).subscribe(
      data => {
        this.fillContract(data)
      },
      err =>{
        console.log(err)

      }
    );
  }

getUsers() {
  this.busy = this.ttService.getUserByCompId(sessionStorage.getItem("compId")).subscribe(
    data => { 
      this.users = data
      if (this.contractId) {
        if (!this.users.find(user => user.id == this.contract.author.id)) {
            this.users.push(Object.assign({}, this.contract.author));
        }
    }
    },
    err => {
      this.ttService.checkErr(err,'error get Users', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.errorerrorMessage,
        timeout: 5000
      });
    }
  );
}

getCustomers(){
  this.busy = this.ttService.getCustomersAlphabeticalOrder(sessionStorage.getItem("compId"), -1).subscribe(
    data =>{
      this.customers = data["customer"];
    },
    err =>{
      this.ttService.checkErr(err,'error get Systems', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.errorerrorMessage,
        timeout: 5000
      });
    },
    () => console.log("Done loading Systems")
  )
}

getSystems(){
  this.busy = this.ttService.getSystems(this.companyId).subscribe(
    data =>{
      this.contractSystems = [];
      this.systems = data['systems'];
      for(let system of this.systems){
        if(system.contract){
          if(system.contract.id == this.contractId){
            this.contractSystems.push(system);
          }
        }
      }
    },
    err =>{
      this.ttService.checkErr(err,'error get Systems', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.errorerrorMessage,
        timeout: 5000
      });
    },
    () => console.log("Done loading Systems")
  )
}

getContractHistory(pagination) {
  this.busy = this.ttService.getContractHistory(pagination).subscribe(
    data => { 
      this.contractHistories = data["contractLogs"];
      for(let history of this.contractHistories){
        history["userName"] = history.author.fullName;

        this.historyDate = new Date();
        this.historyDate.setTime(history.dateCreated);
        history["date"] = this.historyDate.getFullYear() + "-" + ("0" + (this.historyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.historyDate.getDate()).slice(-2)+ " - " + ("0" + this.historyDate.getHours()).slice(-2) + ":" + ("0" + this.historyDate.getMinutes()).slice(-2);
      }
      this.totalPages = data["pageNumber"];
      this.totalElements = data["elementNumber"];
      this.fullLog = this.contractHistories;
    });
}

completeHour(index){
  if(index=='from'){
    if(this.serviceTimeFrom.length==1){
      this.serviceTimeFrom = '0'+this.serviceTimeFrom+'00'
    }else if(this.serviceTimeFrom.length==2){
      this.serviceTimeFrom = this.serviceTimeFrom+'00'
    }else if(this.serviceTimeFrom.length==4){
      this.serviceTimeFrom = this.serviceTimeFrom+'0'
    }
  } else if(index=='to'){
    if(this.serviceTimeTo.length==1){
      this.serviceTimeTo = '0'+this.serviceTimeTo+'00'
    }else if(this.serviceTimeTo.length==2){
      this.serviceTimeTo = this.serviceTimeTo+'00'
    }else if(this.serviceTimeTo.length==4){
      this.serviceTimeTo = this.serviceTimeTo+'0'
    }
  }
}

contractValidation(){
  let result = true;
  
  if(!(this.timeToDecimal(this.serviceTimeTo) > this.timeToDecimal(this.serviceTimeFrom))){
    result = false;
    alert('The field value Service Time (To) must be higher than the field value Service Time (From).')
  }  
  return result;
}

timeToDecimal(time){
  var hoursMinutes = time.split(/[.:]/);
  var hours = parseInt(hoursMinutes[0], 10);
  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
}

fillContract(contract) {
  if(contract != null){
    this.contract = contract;

    if(this.event){
      this.getContractHistory(this.contract.id+'?page='+this.event.page+'&size=10');
    }else{
      this.getContractHistory(this.contract.id+'?page='+ 0 +'&size=10');
    }

    this.nameSelected = contract.name;
    this.nameSelectedOld = contract.name;

    this.minimumAmount = 0;

    if(contract.minimumAmount){
      this.minimumAmount = contract.minimumAmount;
    }
    this.minimumAmountOld = this.minimumAmount;
    
    this.descriptionSelected = contract.description;
    if (this.descriptionSelected == null) this.descriptionSelected = '';
    this.descriptionSelectedOld = contract.description;
    if (this.descriptionSelectedOld == null) this.descriptionSelectedOld = '';

    this.statusSelected = contract.status;
    this.statusSelectedOld = contract.status;

    if(contract.author){
      this.authorSelected = contract.author.id;
      this.authorSelectedOld = contract.author.id;
      this.authorNameOld = contract.author.name;
      this.authorName = "";
    }

    if(contract.customer){
      this.customerSelectedOld = contract.customer.custId;
      this.customerSelected = this.customerSelectedOld;
      this.customerNameOld = contract.customer.custName;
      this.customerName = "";
    }
    // this.comment = contract.comment;
    // if (this.comment == null) this.comment = '';
    // this.commentOld = contract.comment;
    // if (this.commentOld == null) this.commentOld = '';

    if(this.contractId) {
      this.titleHeader = 'View Contract # ' + this.contractId;
    }

    if(contract.serviceTimeFrom != null){
      this.serviceTimeFrom = contract.serviceTimeFrom;
      this.serviceTimeFromOld = contract.serviceTimeFrom;
    }

    if(contract.serviceTimeTo!= null){
      this.serviceTimeTo = contract.serviceTimeTo;
      this.serviceTimeToOld = contract.serviceTimeTo;
    }
    
    if (contract.contractPeriodFrom != null) {
      let contractPeriodFrom = new Date(contract.contractPeriodFrom).toISOString();
      this.tempContractPeriodFrom = contractPeriodFrom.split("T")
      this.tempContractPeriodFromTr = this.tempContractPeriodFrom[0].split("-");
      this.contractPeriodFrom = { year: parseInt(this.tempContractPeriodFromTr[0]), month: parseInt(this.tempContractPeriodFromTr[1]), day: parseInt(this.tempContractPeriodFromTr[2]) };
      this.contractPeriodFromOld = { year: parseInt(this.tempContractPeriodFromTr[0]), month: parseInt(this.tempContractPeriodFromTr[1]), day: parseInt(this.tempContractPeriodFromTr[2]) };
    }

    if (contract.contractPeriodTo != null) {
      let contractPeriodTo = new Date(contract.contractPeriodTo).toISOString();
      this.tempContractPeriodTo = contractPeriodTo.split("T")
      this.tempContractPeriodToTr = this.tempContractPeriodTo[0].split("-");
      this.contractPeriodTo = { year: parseInt(this.tempContractPeriodToTr[0]), month: parseInt(this.tempContractPeriodToTr[1]), day: parseInt(this.tempContractPeriodToTr[2]) };
      this.contractPeriodToOld = { year: parseInt(this.tempContractPeriodToTr[0]), month: parseInt(this.tempContractPeriodToTr[1]), day: parseInt(this.tempContractPeriodToTr[2]) };
    }
  }else{
    bootbox.alert('Contract not found');
    this.close();
  }
}

fieldChanged(event, param) {

}

saveContract(){
  if(this.contractValidation()){
    if(!this.contractId || this.contractId == 0) {
      this.createContract();
    } else {
      this.updateContract();
    }
  }
}

createContract() {
  let invalidDates = new Array();
  let customer;
  
  if(this.validateDates(this.contractPeriodFrom) == false){
    invalidDates.push("\n- Contract Period (From): " + this.dateError);
  }
  
  if(this.validateDates(this.contractPeriodTo) == false){
    invalidDates.push("\n- Contract Period (To): " + this.dateError);
  }
  
  if(invalidDates.length == 0){
    let contractPeriodFromDate = null;
    let contractPeriodToDate = null;

    
    this.author = {"id" : this.authorSelected};
    if(this.authorSelected == ""){
      this.author = null;
    }

    customer = {"custId" : this.customerSelected};
    if(this.customerSelected == ""){
      customer = null;
    }
    
    if(this.contractPeriodFrom && this.contractPeriodFrom.day && this.contractPeriodFrom.month) {
      contractPeriodFromDate = new Date();
      contractPeriodFromDate.setFullYear(this.contractPeriodFrom.year);
      contractPeriodFromDate.setMonth(this.contractPeriodFrom.month-1);  
      contractPeriodFromDate.setDate(this.contractPeriodFrom.day);
    }

    if(this.contractPeriodTo && this.contractPeriodTo.day && this.contractPeriodTo.month) {
      contractPeriodToDate = new Date();
      contractPeriodToDate.setFullYear(this.contractPeriodTo.year);
      contractPeriodToDate.setMonth(this.contractPeriodTo.month-1);  
      contractPeriodToDate.setDate(this.contractPeriodTo.day);
    }
    
    this.busy = this.ttService.createContract({
      "name"                 : this.nameSelected,
      "description"          : this.descriptionSelected,
      "author"               : this.author,
      "minimumAmount"        : this.minimumAmount,
      "serviceTimeFrom"   : this.serviceTimeFrom,
      "serviceTimeTo"     : this.serviceTimeTo,
      "customer"          : customer,
      "company"           : {"id": sessionStorage.getItem("compId")},
      "contractPeriodFrom"         : contractPeriodFromDate,
      "contractPeriodTo"           : contractPeriodToDate,
      "status"               : this.statusSelected,
      "activated"            : true
    }).subscribe(
      response => {
        this.contractId = response["id"];
        this.cohiDescription = 'Created Contract';
        this.createContractHistory("INSERT");
        alert('Contract is saved.');
        this.close();
      },
      err => {
        this.ttService.checkErr(err,'error create contract', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });
        
      });
  }else{
    alert("The following field(s) is(are) invalid:" + invalidDates.join(""))
  }
}

updateContract() {
  let invalidDates = new Array();
  let customer;
  if(this.validateDates(this.contractPeriodFrom) == false){
    invalidDates.push("\n- Contract Period (from): " + this.dateError);
  }
  
  if(this.validateDates(this.contractPeriodTo) == false){
    invalidDates.push("\n- Contract Period (to): " + this.dateError);
  }
  
  if(invalidDates.length == 0){
    let contractPeriodFromDate = null;
    let contractPeriodFromDateOld = null;
    let contractPeriodToDate = null;
    let contractPeriodToDateOld = null;

    this.author = {"id" : this.authorSelected};
    if(this.authorSelected == ""){
      this.author = null;
    }

    customer = {"custId" : this.customerSelected};
    if(this.customerSelected == ""){
      customer = null;
    }

    if (this.contractPeriodFrom != null) {
      contractPeriodFromDate = new Date();
      contractPeriodFromDate.setFullYear(this.contractPeriodFrom.year);
      contractPeriodFromDate.setMonth(this.contractPeriodFrom.month - 1);
      contractPeriodFromDate.setDate(this.contractPeriodFrom.day);
    }
    if (this.contractPeriodFromOld != null) {
      contractPeriodFromDateOld = new Date();
      contractPeriodFromDateOld.setFullYear(this.contractPeriodFromOld.year);
      contractPeriodFromDateOld.setMonth(this.contractPeriodFromOld.month - 1);
      contractPeriodFromDateOld.setDate(this.contractPeriodFromOld.day);
    }
    if (this.contractPeriodTo != null) {
      contractPeriodToDate = new Date();
      contractPeriodToDate.setFullYear(this.contractPeriodTo.year);
      contractPeriodToDate.setMonth(this.contractPeriodTo.month - 1);
      contractPeriodToDate.setDate(this.contractPeriodTo.day);
    }
    if (this.contractPeriodToOld != null) {
      contractPeriodToDateOld = new Date();
      contractPeriodToDateOld.setFullYear(this.contractPeriodToOld.year);
      contractPeriodToDateOld.setMonth(this.contractPeriodToOld.month - 1);
      contractPeriodToDateOld.setDate(this.contractPeriodToOld.day);
    }

    this.busy = this.ttService.updateContract({
      "name"                 : this.nameSelected,
      "description"          : this.descriptionSelected,
      "author"               : this.author,
      "minimumAmount"        : this.minimumAmount,
      "serviceTimeFrom"      : this.serviceTimeFrom,
      "serviceTimeTo"        : this.serviceTimeTo,
      "customer"             : customer,
      "company"              : {"id": sessionStorage.getItem("compId")},
      "contractPeriodFrom"   : contractPeriodFromDate,
      "contractPeriodTo"     : contractPeriodToDate,
      "status"               : this.statusSelected,
      "activated"            : true
    }, this.contractId).subscribe(
      response => {
        let desc: string = null;
        this.cohiDescription = '';

        if (this.nameSelected != this.nameSelectedOld) {
          desc = "'Name' changed from [" + this.nameSelectedOld + "] to [" + this.nameSelected + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        if (this.minimumAmount != this.minimumAmountOld) {
          desc = "'Minimum Monthly Hours' changed from [" + this.minimumAmountOld + "] to [" + this.minimumAmount + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        if (this.descriptionSelected != this.descriptionSelectedOld) {
          desc = "'Description' changed from [" + this.descriptionSelectedOld + "] to [" + this.descriptionSelected + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        if (this.statusSelected != this.statusSelectedOld) {
          if(this.statusSelected == "true"){
            this.statusSelected = "Active";
          }else{
            this.statusSelected = "Inactive";
          }

          if(this.statusSelectedOld == true){
            this.statusSelectedOld = "Active";
          }else{
            this.statusSelectedOld = "Inactive";
          }
          desc = "'Status' changed from [" + this.statusSelectedOld + "] to [" + this.statusSelected + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        if(this.serviceTimeFrom != this.serviceTimeFromOld){
          desc = "'Service Time (From)' changed from [" + this.serviceTimeFromOld + "] to [" + this.serviceTimeFrom + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        if(this.serviceTimeTo != this.serviceTimeToOld){
          desc = "'Service Time (To)' changed from [" + this.serviceTimeToOld + "] to [" + this.serviceTimeTo + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        if(contractPeriodFromDate != null || contractPeriodFromDateOld != null){
          //Contract Period (from) alterado
          if(contractPeriodFromDate != null && contractPeriodFromDateOld != null){
            if((contractPeriodFromDate.getFullYear() != contractPeriodFromDateOld.getFullYear()) || 
              (contractPeriodFromDate.getMonth() != contractPeriodFromDateOld.getMonth()) || 
              (contractPeriodFromDate.getDate() != contractPeriodFromDateOld.getDate())
              ) {
                desc = "'Contract Period (From)' changed from [" + contractPeriodFromDateOld.getFullYear() + "-" + ("0" + (contractPeriodFromDateOld.getMonth() + 1)).slice(-2) +
                "-" + ("0" + contractPeriodFromDateOld.getDate()).slice(-2) + "] to [" + contractPeriodFromDate.getFullYear() +
                "-" + ("0" + (contractPeriodFromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + contractPeriodFromDate.getDate()).slice(-2) + "]";

                if(this.cohiDescription != ''){
                  this.cohiDescription += ", "
                }
                this.cohiDescription += desc;
            }
          }
          //Contract Period (from) era null  
          else if(contractPeriodFromDate != null){
            desc = "'Contract Period (From)' changed from [] to [" + contractPeriodFromDate.getFullYear() +
            "-" + ("0" + (contractPeriodFromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + contractPeriodFromDate.getDate()).slice(-2) + "]";

            if(this.cohiDescription != ''){
              this.cohiDescription += ", "
            }
            this.cohiDescription += desc;
          }
          //Contract Period (from) alterado para null  
          else if(contractPeriodFromDateOld != null){
            desc = "'Contract Period (From)' changed from [" + contractPeriodFromDateOld.getFullYear() + "-" + ("0" + (contractPeriodFromDateOld.getMonth() + 1)).slice(-2) +
                "-" + ("0" + contractPeriodFromDateOld.getDate()).slice(-2) + "] to []";

            if(this.cohiDescription != ''){
              this.cohiDescription += ", "
            }
            this.cohiDescription += desc;
          }
        }

        if(contractPeriodToDate != null || contractPeriodToDateOld != null){
          //Contract Period (to) alterado
          if(contractPeriodToDate != null && contractPeriodToDateOld != null){
            if((contractPeriodToDate.getFullYear() != contractPeriodToDateOld.getFullYear()) || 
              (contractPeriodToDate.getMonth() != contractPeriodToDateOld.getMonth()) || 
              (contractPeriodToDate.getDate() != contractPeriodToDateOld.getDate())
              ) {
                desc = "'Contract Period (To)' changed from [" + contractPeriodToDateOld.getFullYear() + "-" + ("0" + (contractPeriodToDateOld.getMonth() + 1)).slice(-2) +
                "-" + ("0" + contractPeriodToDateOld.getDate()).slice(-2) + "] to [" + contractPeriodToDate.getFullYear() +
                "-" + ("0" + (contractPeriodToDate.getMonth() + 1)).slice(-2) + "-" + ("0" + contractPeriodToDate.getDate()).slice(-2) + "]";

                if(this.cohiDescription != ''){
                  this.cohiDescription += ", "
                }
                this.cohiDescription += desc;
            }
          }

          //Contract Period (To) era null  
          else if(contractPeriodToDate != null){
            desc = "'Contract Period (To)' changed from [] to [" + contractPeriodToDate.getFullYear() +
            "-" + ("0" + (contractPeriodToDate.getMonth() + 1)).slice(-2) + "-" + ("0" + contractPeriodToDate.getDate()).slice(-2) + "]";

            if(this.cohiDescription != ''){
              this.cohiDescription += ", "
            }
            this.cohiDescription += desc;
          }
          //Contract Period (to) alterado para null  
          else if(contractPeriodToDateOld != null){
            desc = "'Contract Period (To)' changed from [" + contractPeriodToDateOld.getFullYear() + "-" + ("0" + (contractPeriodToDateOld.getMonth() + 1)).slice(-2) +
                "-" + ("0" + contractPeriodToDateOld.getDate()).slice(-2) + "] to []";

            if(this.cohiDescription != ''){
              this.cohiDescription += ", "
            }
            this.cohiDescription += desc;
          }
        }
        
        if (this.authorSelected != this.authorSelectedOld) {
          for(let author of this.users){
            if(author["id"] == this.authorSelected ){
              this.authorName = author["fullName"];
            }
            if(author["id"] == this.authorSelectedOld ){
              this.authorNameOld = author["fullName"];
            }
          }
          desc = "'Responsible' changed from [" + this.authorNameOld + "] to [" + this.authorName + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }
        if (this.customerSelected != this.customerSelectedOld) {
          for(let customer of this.customers){
            if(customer["custId"] == this.customerSelected){
              this.customerName = customer["custName"];
            }
          }
          desc = "'Customer' changed from [" + this.customerNameOld + "] to [" + this.customerName + "]";
          if(this.cohiDescription != ''){
            this.cohiDescription += ", "
          }
          this.cohiDescription += desc;
        }

        alert('Contract is saved.');
        if (this.cohiDescription != "") {
          this.createContractHistory("UPDATE");
        }
        this.fillContract(response);
      },
      err => {
        this.ttService.checkErr(err, 'error update contract', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      });
  }else{
    alert("The following field(s) is(are) invalid:" + invalidDates.join(""))
  }
}

createContractHistory(action) {
  this.ttService.createContractHistory({
    'action': action,
    'description': this.cohiDescription
  },this.contractId, sessionStorage.getItem('userId')).subscribe(
    response => {
      this.cohiDescription = '';
      this.values = [];
      if(this.event){
        this.getContractHistory(this.contract.id+'?page='+this.event.page+'&size=10');
      }else{
        this.getContractHistory(this.contract.id+'?page='+ 0 +'&size=10');
      }
    },
    err => {
      this.ttService.checkErr(err, 'error loading contract history', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.error.errorMessage,
        timeout: 5000
      });
    },
  );
}

saveComment(){
  this.createContractHistory('COMMENT');
  this.newVersionModalRef.close('')
}

openComment(template: TemplateRef<any>, task) {
 this.newVersionModalRef = this.ngbModal.open(template);
}

openHistory(template: TemplateRef<any>, row) { 
  this.logTitle = row.id + " - " + row.action;
  this.popullationViewLog(row)
  this.logDescModalRef = this.ngbModal.open(template);
}

openSystem(action, template: TemplateRef<any>) {
  if(action == "Add"){
    let modalDialog = this.ngbModal.open(SystemDialogComponent, { size: 'lg' });
    modalDialog.componentInstance.contractId = this.contractId;
  }
  if(action == "View"){
    this.getSystems();
    this.newVersionModalRef = this.ngbModal.open(template);
  }  
}

validateDates(date){  
  //Parametros para validação.
  let regex = new RegExp('^([0-9]{4}-[0-9]{2}-[0-9]{2})$', 'g');
  let isValid = true;
  //Mensagens de erro.
  let invalidFormat = "Invalid date format."
  let invalidRange = "Invalid date range."
  this.dateError = null;
  //Caso a data seja inválida ela se tornará uma string.
  if(typeof date === "string"){
    //Caso a string tenha o formato correto, o erro é de range.
    if(regex.test(date)){
      isValid = false;
      this.dateError = invalidRange;
    //Caso a string não tenha o formato correto, o erro é de formato.
    } else{
      isValid = false;
      this.dateError = invalidFormat;
    }
  //Caso a data seja do tipo date ela deverá ficar entre os anos de 1969 e 2099.
  } else if(date.year < 1969 || date.year > 2099){
    //Se a data possuir 4 digitos, o erro é de range.
    if(date.year.toString().length == 4){
      this.dateError = invalidRange;
    //Se a data possuir mais ou menos que 4 digitos, o erro é de formato.
    } else{
      this.dateError = invalidFormat;
    }
    isValid = false;      
  }
  return isValid;
}

close() {
    this.modalRef.close('');
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.event = event;
    this.getContractHistory(this.contract.id+'?page='+this.event.page+'&size=10');
  }

  open (template, options) {
    this.newVersionModalRef = this.ngbModal.open(template, options);
  }

  popullationViewLog(row) {
    this.logDescription = new Array();
    let positions = new Array();
    if(row.action == "UPDATE"){
      positions.push(parseInt(row.description.indexOf("'Name'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Minimum Monthly Hours'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Description'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Status'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Service Time (From)'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Service Time (To)'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Contract Period (From)'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Contract Period (To)'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Responsible'"),10));
      positions.push(parseInt(row.description.indexOf("'Customer'"),10));
      positions.push(row.description.length); 
      positions.sort(function(a, b) { return a - b; });

      for(let position in positions){
        let aux = parseInt(position, 10);
        if(positions[aux] != -1 && aux != positions.length-1){
          // The last vector it is not necessary to remove the commas
          if(aux == positions.length-2){
             // Business rule to show a maximum of 300 characters
             if((positions[aux+1] - positions[aux])>300){
               this.logDescription.push(row.description.substring(positions[aux], positions[aux]+300)+'...');
             }else{
               this.logDescription.push(row.description.substring(positions[aux], positions[aux+1]));
             }
          // Remove the commas
          }else{
            // Business rule to show a maximum of 300 characters
            if((positions[aux+1] - positions[aux])>300){
              this.logDescription.push(row.description.substring(positions[aux], positions[aux]+300)+'...');
            }else{
              this.logDescription.push(row.description.substring(positions[aux], positions[aux+1]-2));
            }
          }
        }
      }
    }else{
      this.logDescription.push(row.description);
    }
  }
}