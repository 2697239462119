﻿import { HttpClient, HttpHeaders } from '@angular/common/http';

import {map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {User} from '../model/model.user';

import {AppComponent} from '../app.component';
@Injectable()
export class AuthService {

  constructor(public http: HttpClient) { }

  public logIn(user: User) {
    // creating base64 encoded String from user name and password
    let base64Credential: string = btoa( user.username+ ':' + user.password);
    let body = JSON.stringify(user);
    return this.http.post(AppComponent.API_URL + '/account/login' , body,  { headers: {
      'Accept': 'application/json',
      'Authorization': 'Basic ' + base64Credential
    } } );
  }

  logOut() {
    // remove user from local storage to log user out
    return this.http.post(AppComponent.API_URL+'/logout',{}).pipe(
      map((response: Response) => {
      }));

  }

  sendPasswordResetEmail(email: string) {
    return this.http.post(AppComponent.API_URL + '/account/sendPasswordReset/' + email, email).pipe(
      map((response: Response) => {
        console.log('sent Password Reset Email!')
      }));
  }

  public resetPassword(user: User, token: String) {
    return this.http.post(AppComponent.API_URL + '/account/reset/' + token, user).pipe(
      map((response: Response) => {
        console.log('password reseted!')
      }));
  }
}