import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { TimeTrackingService } from './../../../services/time-tracking.service';
import { Subscription } from 'rxjs';
import { isThisMonth, distanceInWordsToNow } from 'date-fns';

@Component({
  selector: 'app-attachments-dialog',
  templateUrl: './attachments-dialog.component.html'
})
export class AttachmentsDialogComponent implements OnInit {
  @Input() enableUpload: boolean;
  
  public log : {
    index       : number,
    action      : string,
    description : string
  }[];

  // Similar ao que está no BackEnd, ~350MB
  // Sempre mudar nos dois projetos (AmazonUtilsService no backend)
  // Atualizado pata máximo de 1MB
  maxSize = 1000000;

  fileButtonText = 'Choose';

  id: number;
  tableName: any;
  tableId: any;

  index: number;

  busy: Subscription;

  action  : string = 'FILE';

  columns: Array<any> = [
    //{ field: 'id', header: 'ID' },
    { field: 'fileName', header: 'File Name' },
    { field: 'uploadDate', header: 'Upload Date' },
    { field: 'lastDownloadUser', header: 'Last Down. User' },
    { field: 'lastDownloadDate', header: 'Last Down. Date' }];

  files: any;
  file: any = {
  };

  constructor(private ngbModal: NgbActiveModal, private ttService: TimeTrackingService) { }

  ngOnInit() {
    this.getFiles();
    this.index = 0;
    this.log = new Array();
  }

  cancelFile() {
    this.file = {};
    this.fileButtonText = 'Choose';
  }

  chooseFile(file) {
    this.fileButtonText = 'Cancel';

    if (file.size > this.maxSize) {
      alert('File size exceeds limit...')
      this.cancelFile();
    } else {
      this.file = file;
    }
  }

  close() {
    this.ngbModal.close(this.log);
  }

  getFiles() {
    this.ttService.getFiles(this.tableName, this.tableId).subscribe(
      response => {
        this.files = response;
      }
    )
  }

  downloadFile(file) {
    const s3 = {
      'id': file.id,
      'tableName': this.tableName,
      'tableId': this.tableId,
      'bucketName': 'bucket' + sessionStorage.getItem('compName').toLowerCase(),
      'fileName': file.fileName,
      'lastDownloadUser': sessionStorage.getItem('username'),
      'uploadDate': file.uploadDate
    };

    this.busy = this.ttService.downloadFile(s3).subscribe(response => {
      // Simula click em link para download do byte response
      // Se achar algo melhor, por favor refatore! - Raul @ 2018
      var url = window.URL.createObjectURL(response);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = s3.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  deleteFile(s3AWS) {
    this.busy = this.ttService.deleteFile(s3AWS).subscribe(
      response => {
        const index = this.files.indexOf(s3AWS);
        this.files.splice(index, 1);
        this.log.push({
          index: this.index ++,
          action: this.action,
          description : 'File ' + s3AWS.fileName + " deleted."
        });
      }
    );
  }

  uploadFile() {
    const formData: FormData = new FormData();
    formData.append('info', JSON.stringify(
      {
        tableName: this.tableName,
        tableId: this.tableId,
        bucket: 'bucket' + sessionStorage.getItem('compName')
      }));

    formData.append('file', this.file, this.file.name);

    this.busy = this.ttService.uploadFile(formData).subscribe(
      response => {
        this.getFiles();
        this.log.push({
          index: this.index ++,
          action: this.action,
          description : 'File ' + this.file.name + " added."
        });
        this.cancelFile();        
      }, e => {
        if (e.error.message.includes('FILE_DUPLICATE')) alert('File already uploaded...')
        if (e.error.message.includes('FILE_MAX_SIZE')) alert('File size exceeds limit...')
      }
    );
  }
}