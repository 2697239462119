import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class UrlPermission implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('withCredentials') == 'true') {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
   if (window.location.href.indexOf('reset-password') >= 0) {
    var url = window.location.href;
    var token = url.substring(url.length - 36);
    console.info('TOKEN: ' + token);
    this.router.navigate(['/reset-password'],{ queryParams: { token: token } });
   } else {
    this.router.navigate(['/login'], {
      queryParams: {
        return: state.url
      }
    });
   }
    return false;
  }
}
