import { CustomBusyComponent } from './custom.busy.component.';
import { BusyConfig } from 'ng-busy';

// Loader template
export const busyConfig: BusyConfig = {
    message: "",
    template: CustomBusyComponent,
    templateNgStyle: {},
    delay: 200,
    backdrop: true,
    wrapperClass: "ng-busy",
    disableAnimation: true,
    minDuration: 300
  };
  