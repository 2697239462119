import { Component, Injectable, OnInit, Input } from "@angular/core";
import { TimeTrackingService } from "../../../services/time-tracking.service";
import { NgbDateStruct, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { invalid } from '@angular/compiler/src/render3/view/util';


@Component({
    selector: 'milestone-dialog',
    templateUrl: './milestone-dialog.component.html'
})
@Injectable()

export class MilestoneDialogComponent implements OnInit {
    constructor(private ttService: TimeTrackingService, private modalRef: NgbActiveModal) {}
    @Input() projectId: any;
    @Input() mileId: any;
    @Input() tree: boolean = true;

    public projects;

    busy: Subscription;
    
    mileProjectType: string;
    modelStartDate: NgbDateStruct;
    modelFinishDate: NgbDateStruct;
    dateError: string;
    actionsButton: boolean = false;
    hasTree: boolean = true;

    milestoneObj = {
        id: null,
        name: '',
        description: '',
        creationUser: null,
        dueDate: null,
        startDate: null,
        finishDate: null
    };

    alertsDismiss: any = [];
    reason: string = null; 

    titleHeader: string;

    ngOnInit() {
        this.titleHeader = 'New Phase';

        this.hasTree = this.tree || this.mileId ? true : false;

        if (this.projectId) {
            this.getProjectById(this.projectId);
        } else if (!this.tree) this.getProjects();

        if (this.mileId) {
            this.titleHeader = 'Edit Phase #' + this.mileId;
            this.actionsButton = true;
            this.getMilestone();
        }
    }

    close() {
        this.modalRef.close(this.reason);
    }

    saveMilestone() {
        if(!this.mileId || this.mileId == 0) {
            this.createMilestone();
        } else {
            this.updateMilestone();
        }
    }

    createMilestone() {
        let invalidDates = new Array();

        if(!this.validateDates(this.modelStartDate))
            invalidDates.push("\n- Phase Planned Start: " + this.dateError);

        if(!this.validateDates(this.modelFinishDate))
            invalidDates.push("\n- Phase Planned Finish: " + this.dateError);

        if(!invalidDates.length) {
            let startDate = null;
            let finishDate = null;
            if(this.modelStartDate && this.modelStartDate.day && this.modelStartDate.month) {
                startDate = new Date();
                startDate.setFullYear(this.modelStartDate.year);
                startDate.setDate(this.modelStartDate.day);
                startDate.setMonth(this.modelStartDate.month-1);  
            }
            if(this.modelFinishDate && this.modelFinishDate.day && this.modelFinishDate.month) {
                finishDate = new Date();
                finishDate.setFullYear(this.modelFinishDate.year);
                finishDate.setDate(this.modelFinishDate.day);
                finishDate.setMonth(this.modelFinishDate.month-1);  
            }
            this.busy = this.ttService.createPhase({
                "project": {"id": this.projectId},
                "creationUser": {"id": sessionStorage.getItem('userId')},
                "projectType": this.mileProjectType,
                "name": this.milestoneObj.name,
                "description": this.milestoneObj.description,
                "dueDate": finishDate,
                "startDate": startDate,
                "finishDate": finishDate,
                "company" : {"id": sessionStorage.getItem('compId')}
            }).subscribe(
            data => {
                this.reason = 'node update';
                alert('Phase #' + data['id'] + ' created.');
                this.close();
            },
            err => {
                this.ttService.checkErr(err,'error creating phase', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            });
        } else {
            alert("The following field(s) is(are) invalid:" + invalidDates.join(""));
        }
    }  

    updateMilestone() {
        let invalidDates = new Array();

        if(!this.validateDates(this.modelStartDate))
            invalidDates.push("\n- Phase Planned Start: " + this.dateError);

        if(!this.validateDates(this.modelFinishDate))
            invalidDates.push("\n- Phase Planned Finish: " + this.dateError);

        if(!invalidDates.length) {
            let startDate = null;
            let finishDate = null;
            if(this.modelStartDate && this.modelStartDate.day && this.modelStartDate.month) {
                startDate = new Date();
                startDate.setFullYear(this.modelStartDate.year);
                startDate.setMonth(this.modelStartDate.month-1); 
                startDate.setDate(this.modelStartDate.day); 
            }
            if(this.modelFinishDate && this.modelFinishDate.day && this.modelFinishDate.month) {
                finishDate = new Date();
                finishDate.setFullYear(this.modelFinishDate.year);
                finishDate.setMonth(this.modelFinishDate.month-1); 
                finishDate.setDate(this.modelFinishDate.day); 
            }
            this.busy = this.ttService.updatePhase({
                "id": this.mileId,
                "project": {"id": this.projectId},
                "creationUser": this.milestoneObj.creationUser,
                "updateUser": {"id": sessionStorage.getItem('userId')},
                "projectType": this.mileProjectType,
                "name": this.milestoneObj.name,
                "description": this.milestoneObj.description,
                "dueDate": finishDate,
                "startDate": startDate,
                "finishDate": finishDate,
                "company" : {"id": sessionStorage.getItem('compId')}
            }, this.mileId).subscribe(
                response => {
                    this.reason = 'node update';
                    alert('Phase is saved.')
                    this.close();
                },
                err => {
                    this.ttService.checkErr(err,'error updating phase', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                });
            });
        } else {
            alert("The following field(s) is(are) invalid:" + invalidDates.join(""))
        }
    }

    deleteMilestone() {
        if (confirm('Are you sure you want to delete the phase "' + this.milestoneObj.name + '"?')) {
            this.busy = this.ttService.deletePhase(this.milestoneObj).subscribe(
                response => {
                    this.reason = 'node update';
                    alert('Phase #' + this.milestoneObj.id + ' deleted.');
                    this.close();
                },
                err => {
                    alert(err.error.errorMessage);
                    this.ttService.checkErr(err,'error deleting phase', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                }
            );
        }
    }

    getMilestone() {
        this.busy = this.ttService.getPhaseById(this.mileId).subscribe(
            data => {
                this.milestoneObj = {"id": data['id'], "name": data['name'], "description": data['description'], "creationUser": data['creationUser'], "dueDate": data['dueDate'], "startDate": data['startDate'], "finishDate": data['finishDate']};
                /*let duoDate = new Date(this.milestoneObj.dueDate);
                this.modelDueDate = {year: duoDate.getFullYear(), month: duoDate.getMonth() + 1, day: duoDate.getDate() + 1};*/

                let startDate = new Date(this.milestoneObj.startDate);
                this.modelStartDate = {year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate()};

                let finishDate = new Date(this.milestoneObj.finishDate);
                this.modelFinishDate = {year: finishDate.getFullYear(), month: finishDate.getMonth() + 1, day: finishDate.getDate()};
            },
            err => {
            this.ttService.checkErr(err,'error loading phase', this.modalRef);
            this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
                });
            },
            () => console.log('done loading phase')
        );
    }

    getProjectById(projectId) {
        this.busy = this.ttService.getProjectsById(projectId).subscribe(
            data => {this.projects = data;},
            err => {
                this.projects = [];
                this.ttService.checkErr(err,'error loading project', this.modalRef);
                this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
                });
            },
            () => {console.log('Done loading project');}
        );
    }

    getProjects(){
        this.busy = this.ttService.getProjectsNotClosed(sessionStorage.getItem('compId')).subscribe(
            // the first argument is a function which runs on success
            data => { this.projects = data;
            },
            // the second argument is a function which runs on error
            err => {
                this.projects = [];
                this.ttService.checkErr(err,'error loading projects', this.modalRef);
                this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
                });
            },
            // the third argument is a function which runs on completion
            () => console.log('done loading projects')
        );
    }

    validateDates(date){  
        //Parametros para validação.
        let regex = new RegExp('^([0-9]{4}-[0-9]{2}-[0-9]{2})$', 'g');
        let isValid = true;
        //Mensagens de erro.
        let invalidFormat = "Invalid date format."
        let invalidRange = "Invalid date range."
        this.dateError = null;
        //Caso a data seja inválida ela se tornará uma string.
        if(typeof date === "string"){
            //Caso a string tenha o formato correto, o erro é de range.
            if(regex.test(date)) {
                isValid = false;
                this.dateError = invalidRange;
            //Caso a string não tenha o formato correto, o erro é de formato.
            } else {
                isValid = false;
                this.dateError = invalidFormat;
            }
        //Caso a data seja do tipo date ela deverá ficar entre os anos de 1969 e 2099.
        } else if(date.year < 1969 || date.year > 2099){
            //Se a data possuir 4 digitos, o erro é de range.
            if(date.year.toString().length == 4) {
                this.dateError = invalidRange;
            //Se a data possuir mais ou menos que 4 digitos, o erro é de formato.
            } else {
                this.dateError = invalidFormat;
            }
            isValid = false;      
        }
        return isValid;
    }
}