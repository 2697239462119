import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeTrackingService } from '../../../services/time-tracking.service';
import { FollowUpDialogComponent } from '../follow-up-dialog/follow-up-dialog.component';

@Component({
  selector: 'app-project-follow-up',
  templateUrl: './project-follow-up.component.html',
  styleUrls: ['./project-follow-up.component.scss']
})
export class ProjectFollowUpComponent implements OnInit {

  constructor(private ngbModal: NgbModal, private ttService: TimeTrackingService) { }

  @Input() projectId;
  @Output() close: EventEmitter<any> = new EventEmitter();

  followUpData;

  projectCols = [
    { field: 'dateCreated', header: 'Date' },
    { field: 'userFullName', header: 'User' },
    { field: 'phaseDescription', header: 'Phase' },
    { field: 'description', header: 'Follow up' },
    { field: 'issue', header: 'Issue' },
    { field: 'risk', header: 'Risk' },
    { field: 'riskMitigation', header: 'Risk Mitigation' }
  ];

  ngOnInit() {
    this.getFollowUp();
  }

  openDialog() {
    const modalDialog = this.ngbModal.open(FollowUpDialogComponent, { backdrop: 'static', keyboard: false});
    modalDialog.componentInstance.projectId = this.projectId;
    modalDialog.componentInstance.tree = false;
    modalDialog.result.then(() => { this.getFollowUp(); });
  }

  getFollowUp() {
    this.ttService.getFollowUpByProject(this.projectId).subscribe(data => {
      this.followUpData = data;
      for (const followUp of this.followUpData) {
        const date = new Date();
        date.setTime(followUp.dateCreated);
        // tslint:disable-next-line: max-line-length
        followUp.dateCreated = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      }
    });
  }

  openDialogUpdate(id) {
    const modalDialog = this.ngbModal.open(FollowUpDialogComponent);
    modalDialog.componentInstance.projectId = this.projectId;
    modalDialog.componentInstance.id = id;
    modalDialog.componentInstance.tree = false;
    modalDialog.result.then(() => { this.getFollowUp(); });
  }

}
