import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Location }   from '@angular/common';
import { Subscription } from 'rxjs';
import { NgbDateStruct, NgbActiveModal, NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TimeTrackingService } from '../../../services/time-tracking.service';
import { AttachmentsDialogComponent } from './../attachments-dialog/attachments-dialog.component';
import { TemplateRef } from '@angular/core';
import { ConfirmBoxDialogComponent } from '../confirm-box-dialog/confirm-box-dialog.component';
import { NgxMaskModule } from 'ngx-mask';


@Component({
    selector: 'project-dialog',
    templateUrl: './project-dialog.component.html',
    styleUrls: ['./project-dialog.component.css']
})
@Injectable()
export class ProjectDialogComponent implements OnInit {
    constructor(location: Location, private ttService: TimeTrackingService, private ngbModal: NgbModal, private modalClose: NgbActiveModal, private modal: BsModalService) { }
    @Input() projId: any;

    public progressBar;
    public departments;
    public project;
    public progresData;
    public userGp;
    public activeUsers;
    public statuss;
    public projects;
    public companys;
    public teams;
    public parameters;
    public customers;
    
    historyDate: Date;
    projectHistories: any;
    totalPages:   number;
    totalElements:number;
    projectId: number;
    projectfullLogs:any;
    logDescMOdalRef:   NgbModalRef;
    logTitle:               string;
    logDescription:         any;
    event:                  any;
    projHistDescription:any;
    tihiProjDescription:any;
    projectSelected: any;

    busy: Subscription;
    alertsDismiss: any = [];
    projectCols:  any;
    titleHeader: string;
    companySelect: string;
    statusSelect: string;
    statusSelectOld:string;
    departmentSelect: string;
    parameterSelect: string;
    customerSelect: string;
    customerSelectOld:string;
    statusName: string;
    teamSelect: string;
    teamSelectOld: string;
    userSelect: string;
    userSelectOld:string;
    projectDescription: string;
    projectDescriptionOld: string;
    projectNameOld:string;
    projectName: string;
    tempFinishDate: string[];
    tempFinishDateTr: string[];
    tempStartDate: string[];
    tempStartDateTr: string[];
    value: number;
    valueOld: number;
    progress: number;
    custId: number;
    buttonAction: boolean;
    isUpdate: boolean;
    containsTask: boolean;
    containsPhase: boolean;
    modelStart: any;
    modelFinish: any;
    dateError: string;
    newVersionModalRef:     NgbModalRef;
    modalRef: BsModalRef;

    modelStartOld: { year: number; month: number; day: number; };
    modelFinishOld: { year: number; month: number; day: number; };
    
    reason: string = null;
    ngOnInit() {
        sessionStorage.setItem('paraName', 'PROJECT_OPENED, PROJECT_CLOSED');
        this.getStatus();
        this.getCompanys();
        this.getTeams();
        this.getCustomers();
        this.getActiveUsers();
        this.userGp = { "fullName": "" };
        this.progressBar = { "completedPerc": 0 };
        this.modelFinish = null;
        this.modelStart = null;
        this.alertsDismiss = [];
        this.titleHeader = 'New Project';
        this.isUpdate = false;
        this.projectName = '';
        this.projectDescription = '';
        this.progress = 0;
        this.companySelect = sessionStorage.getItem('compName');
        this.statusSelect = '1';
        this.departmentSelect = '';
        this.parameterSelect = '';
        this.customerSelect = '';
        this.projectSelected = '';
        this.teamSelect = '';
        this.userSelect = '';
        this.userSelectOld = '';
        this.value;
        this.departments = [];
        this.modelStart = null;
        this.modelFinish = null;

        this.projectCols = [
            // { field: 'id',     header: 'History ID' },
             { field: 'date',       header: 'Date' },
             { field: 'userName',   header: 'User' },
             { field: 'action', header: 'Action' },
             { field: 'description',   header: 'Description' },
            
             ];  

        if (this.projId != null) {
            this.checkTask();
            this.checkMilestone();
            this.isUpdate = true;
            this.buttonAction = true;
            this.getProject(this.projId);
            this.getUserProjectManager(this.projId);
        }
    }

    fillProject(project){
        this.titleHeader = 'View Project #' + this.projId;
        this.projectId = this.projId;

        this.projectName = project.name;
        this.projectNameOld = this.projectName;

        this.projectDescription = project.description;
        this.projectDescriptionOld = this.projectDescription;

        this.teamSelect = project.teamId ? project.teamId : '';
        this.teamSelectOld = project.teamId ? project.teamId : '';

        this.statusSelect = project.statusId;
        this.statusSelectOld = this.statusSelect;

        if(project.customerId){
            this.customerSelect = project.customerId;      
            this.customerSelectOld = this.customerSelect;
        }

        this.userSelect = project.userByUserId ? project.userByUserId : '';
        this.userSelectOld = project.userByUserId ? project.userByUserId : '';
                
        this.progress = project.progress;
        this.companySelect = sessionStorage.getItem('compName');
        this.statusSelect = project.statusId;
        this.parameterSelect = project.typeParaId;
        this.customerSelect = project.customerId;
        this.getDepartments();
        this.value = project.value;
        this.valueOld = this.value;
        this.departmentSelect = project.departmentId;
        this.modelFinish = project.finishDate;

        if (project.finishDate != null) {
            this.tempFinishDateTr = project.finishDate.split("-");
            this.modelFinish = { year: parseInt(this.tempFinishDateTr[0]), month: parseInt(this.tempFinishDateTr[1]), day: parseInt(this.tempFinishDateTr[2]) };
        }
        if (project.startDate != null) {
            this.tempStartDateTr = project.startDate.split("-");
            this.modelStart = { year: parseInt(this.tempStartDateTr[0]), month: parseInt(this.tempStartDateTr[1]), day: parseInt(this.tempStartDateTr[2]) };
        }
        if(this.event){
            this.getProjectsHistory(this.projId+'?page='+this.event.page+'&size=10');
            }else{
            this.getProjectsHistory(this.projId+'?page='+ 0 +'&size=10');
        }
        if (project.finishDate != null) {
            let finishDate = new Date(project.finishDate).toISOString();
            this.tempFinishDate = finishDate.split("T")
            this.tempFinishDateTr = this.tempFinishDate[0].split("-");
            this.modelFinish = { year: parseInt(this.tempFinishDateTr[0]), month: parseInt(this.tempFinishDateTr[1]), day: parseInt(this.tempFinishDateTr[2]) };
            this.modelFinishOld = { year: parseInt(this.tempFinishDateTr[0]), month: parseInt(this.tempFinishDateTr[1]), day: parseInt(this.tempFinishDateTr[2]) };
            }
        
            if (project.startDate != null) {
            let startDate = new Date(project.startDate).toISOString();
            this.tempStartDate = startDate.split("T")
            this.tempStartDateTr = this.tempStartDate[0].split("-");
            this.modelStart = { year: parseInt(this.tempStartDateTr[0]), month: parseInt(this.tempStartDateTr[1]), day: parseInt(this.tempStartDateTr[2]) };
            this.modelStartOld = { year: parseInt(this.tempStartDateTr[0]), month: parseInt(this.tempStartDateTr[1]), day: parseInt(this.tempStartDateTr[2]) };
            }
    }



    getProject(id) {
        this.busy = this.ttService.getProjectsById(id).subscribe(
            data => {
                this.project = data;
                this.fillProject(this.project[0]);
                // So calcula progress quando pega o project
                this.custId = this.project[0].customerId;
                this.busy = this.ttService.getProgressReports(+id).subscribe(
                        (data: any) => {
                            this.progressBar.completedPerc = data.treeData.data.completedPerc;
                        }
                        , err => {
                            this.ttService.checkErr(err, 'error loading progress', this.modalRef);
                            this.alertsDismiss.push({
                                type: 'danger',
                                msg: err.error.errorMessage,
                                timeout: 5000
                            });
                        },
                        () => console.log('done loading progress')
                    );
            },
            err => {
                this.ttService.checkErr(err, 'error loading companys', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading companys')
        );
    }

    getUsers(teamId) {
        this.busy = this.ttService.getUserByTeamId(teamId).subscribe(
          data => {
                this.activeUsers = data;
          });
    }

    getUserProjectManager(projId) {
        this.ttService.getUserProjectManager(sessionStorage.getItem("compId"), projId).subscribe(
            data => {
                this.userGp = data;
                this.userSelect = this.userGp.id;
                this.userSelectOld = this.userGp.id;
            },
            err => {
                this.ttService.checkErr(err, 'error loading user GP', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            }
        );
    }

    getActiveUsers() {
        this.ttService.getActiveUsers(sessionStorage.getItem("compId")).subscribe(
            data => {
                this.activeUsers = data;
            },
            err => {
                this.ttService.checkErr(err, 'error loading users', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            }
        );
    }

    getDepartments() {
        this.ttService.getDepartmentsForCompIdCustId(sessionStorage.getItem('compId'), this.customerSelect).subscribe(
            data => {
                this.departments = data
            },
            err => {
                this.ttService.checkErr(err, 'error loading departments', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading departments')
        );
    }

    getStatus() {
        this.ttService.getStatusByParameters(sessionStorage.getItem('compId'), sessionStorage.getItem('paraName')).subscribe(
            data => {
                // Project is NEW
                if(this.projId == null) {
                    let listOfStatus = [];
                    (<any[]>data).forEach(statusAsElement => {
                        if(statusAsElement.name == 'Open') {
                            this.statusSelect = statusAsElement.id;
                            listOfStatus.push(statusAsElement);
                        } else if (statusAsElement.name == 'Closed') {
                            listOfStatus.push(statusAsElement);
                        }
                    });
                    this.statuss = listOfStatus;
                // Project is event
                } else {
                    this.statuss = data
                }
                this.statuss.sort((a, b) => a.id - b.id);
            },
            err => {
                this.ttService.checkErr(err, 'error loading status', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading status')
        );
    }


    getCompanys() {
        this.ttService.getCompanys().subscribe(
            data => {
                this.companys = data;
            },
            err => {
                this.ttService.checkErr(err, 'error loading companys', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading companys')
        );
    }

    getTeams() {
        this.ttService.getTeamsByCompId(sessionStorage.getItem('compId')).subscribe(
            data => {
                this.teams = data;
            },
            err => {
                this.ttService.checkErr(err, 'error loading teams', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });

            },
            () => console.log('done loading teams')
        );
    }

    getProjectsHistory(pagination) {
        this.busy = this.ttService.getProjectHistory(pagination).subscribe(
            data => {
                this.projectHistories = data["projectLogs"];
                for(let history of this.projectHistories){
                    history["userName"] = history.author.fullName;
                    this.historyDate = new Date();
                    this.historyDate.setTime(history.dateCreated);
                    history["date"] = this.historyDate.getFullYear() + "-" + ("0" + (this.historyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.historyDate.getDate()).slice(-2)+ " - " + ("0" + this.historyDate.getHours()).slice(-2) + ":" + ("0" + this.historyDate.getMinutes()).slice(-2);
                }
        
            this.totalPages = data["pageNumber"];
            this.totalElements = data["elementNumber"];
        });
      this.projectfullLogs = this.projectHistories;
    }

    createProjectHistory(action) {
        this.ttService.createProjectHistory({
          'action': action,
          'description': this.tihiProjDescription
        },this.projId, sessionStorage.getItem('userId')).subscribe(
          response => {
            this.tihiProjDescription = '';
            if(this.event){
              this.getProjectsHistory(this.projId+'?page='+this.event.page+'&size=10');
            }else{
              this.getProjectsHistory(this.projId+'?page='+ 0 +'&size=10');
            }
          },
          err => {
            this.ttService.checkErr(err, 'error loading system history', this.modalRef);
            this.alertsDismiss.push({
              type: 'danger',
              msg: err.error.errorMessage,
              timeout: 5000
            });
          },
        );
      }

  openHistory(template: TemplateRef<any>, row) { 
    this.logTitle = row.id + " - " + row.action;
    this.populationViewLog(row);
    this.logDescMOdalRef = this.ngbModal.open(template, { backdrop: 'static', keyboard : false });
  }

  populationViewLog(row){
    this.logDescription = new Array();
    let positions = new Array();
    if(row.action == "UPDATE"){
      positions.push(parseInt(row.description.indexOf("'Name'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Description'"),10));
      positions.push(parseInt(row.description.indexOf("'Expected Start'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Expected Finish'"),10));  
      positions.push(parseInt(row.description.indexOf("'Status'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Customer'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Team'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Project Manager'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Value'"),10)); 

      positions.push(row.description.length); 
      positions.sort(function(a, b) { return a - b; });

      for(let position in positions){
        let aux = parseInt(position, 10);
        if(positions[aux] != -1 && aux != positions.length-1){
          // The last vector it is not necessary to remove the commas
          if(aux == positions.length-2){
             // Business rule to show a maximum of 300 characters
             if((positions[aux+1] - positions[aux])>300){
               this.logDescription.push(row.description.substring(positions[aux], positions[aux]+300)+'...');
             }else{
               this.logDescription.push(row.description.substring(positions[aux], positions[aux+1]));
             }
          // Remove the commas
          }else{
            // Business rule to show a maximum of 300 characters
            if((positions[aux+1] - positions[aux])>300){
              this.logDescription.push(row.description.substring(positions[aux], positions[aux]+300)+'...');
            }else{
              this.logDescription.push(row.description.substring(positions[aux], positions[aux+1]-2));
            }
          }
        }
      }
    }else{
        this.logDescription.push(row.description);
    }
  }



    getCustomers() {
        this.ttService.getCustomersAlphabetical(sessionStorage.getItem('compId')).subscribe(
            data => {
                this.customers = data;
            },
            err => {
                this.ttService.checkErr(err, 'error loading departments', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading departments')
        );
    }

    saveProject() {
        if (this.projId == null) {
            this.createProject();
        } else {
            this.updateProject();
        }
    }


    saveComment(){
        this.createProjectHistory('COMMENT');
        this.newVersionModalRef.close('');
        alert('Comment is saved.');
      }

    createProject() {
        let invalidDates = new Array();

        if(!this.validateDates(this.modelStart))
            invalidDates.push("\n- Project Start Date: " + this.dateError);

        if(!this.validateDates(this.modelFinish))
            invalidDates.push("\n- Project Finish Date: " + this.dateError);

        // Only and only if there aren't error on the dates the creation will proceed
        if(!invalidDates.length) {
            var dateFinish = null;
            var dateStart = null;

            if (this.modelFinish && this.modelFinish.day && this.modelFinish.month) {
                dateFinish = new Date();
                dateFinish.setFullYear(this.modelFinish.year);
                dateFinish.setMonth(this.modelFinish.month - 1);
                dateFinish.setDate(this.modelFinish.day);
            }
            if (this.modelStart && this.modelStart.day && this.modelStart.month) {
                dateStart = new Date();
                dateStart.setFullYear(this.modelStart.year);
                dateStart.setMonth(this.modelStart.month - 1);
                dateStart.setDate(this.modelStart.day);
            }

            // Setting an object is easier to fix and change properties if some problem appears
            let creationObj = {
                "name": this.projectName,
                "description": this.projectDescription,
                "startDate": dateStart,
                "finishDate": dateFinish,
                "typeParaId": 1,
                "statusId": this.statusSelect,
                "departmentId": this.departmentSelect,
                "customerId": this.customerSelect,
                "companyId": sessionStorage.getItem('compId'),
                "teamId": this.teamSelect,
                "progress": this.progress,
                "value": this.value,
                "creationUserId": sessionStorage.getItem('userId'),
                "userByUserId": this.userSelect,
            };

            this.busy = this.ttService.createProject(creationObj).subscribe(
                response => {
                    alert('Project is saved.');
                    this.reason = 'node update';
                    this.projId = response;
                    this.tihiProjDescription = 'Created Project ' + this.projId;
                    this.isUpdate = true;
                    this.createTemplate();
                    this.createProjectHistory('INSERT');
                },
                err => {
                    this.ttService.checkErr(err, 'error create project', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                });
        } else {
            this.alertsDismiss.push({
                type: 'danger',
                msg: "The following field(s) is(are) invalid: ",
                timeout: 5000
            });
            for(let errorMessage of invalidDates) {
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: errorMessage,
                    timeout: 5000
                });
            }
            alert("The following field(s) is(are) invalid: " + invalidDates.join(""));
        }
    }

    updateProject() {
        let invalidDates = new Array();

        if(!this.validateDates(this.modelStart))
            invalidDates.push("\n- Project Start Date: " + this.dateError);

        if(!this.validateDates(this.modelFinish))
            invalidDates.push("\n- Project Finish Date: " + this.dateError);

        if(!invalidDates.length) {
            let dateFinish = null;
            let dateStart = null;
            let dateFinishOld = null;
            let dateStartOld = null;

            if (this.modelFinish && this.modelFinish.day && this.modelFinish.month) {
                dateFinish = new Date();
                dateFinish.setFullYear(this.modelFinish.year);
                dateFinish.setMonth(this.modelFinish.month - 1);
                dateFinish.setDate(this.modelFinish.day);
            
            }
            if (this.modelFinishOld && this.modelFinishOld.day && this.modelFinishOld.month) {
                dateFinishOld = new Date();
                dateFinishOld.setFullYear(this.modelFinishOld.year);
                dateFinishOld.setMonth(this.modelFinishOld.month - 1);
                dateFinishOld.setDate(this.modelFinishOld.day);
            }
            if (this.modelStart && this.modelStart.day && this.modelStart.month) {
                dateStart = new Date();
                dateStart.setFullYear(this.modelStart.year);
                dateStart.setMonth(this.modelStart.month - 1);
                dateStart.setDate(this.modelStart.day);
                
            }
            if (this.modelStartOld && this.modelStartOld.day && this.modelStartOld.month) {
                dateStartOld = new Date();
                dateStartOld.setFullYear(this.modelStartOld.year);
                dateStartOld.setMonth(this.modelStartOld.month - 1);
                dateStartOld.setDate(this.modelStartOld.day);
            }
            this.busy = this.ttService.updateProject({
                "id": this.projId,
                "name": this.projectName,
                "description": this.projectDescription,
                "startDate": dateStart,
                "finishDate": dateFinish,
                "typeParaId": 1,
                "statusId": this.statusSelect,
                "departmentId": this.departmentSelect,
                "customerId": this.customerSelect,
                "companyId": sessionStorage.getItem('compId'),
                "teamId": this.teamSelect,
                "progress": this.progress,
                "value": this.value,
                "updateUserId": sessionStorage.getItem('userId'),
                "userByUserId": this.userSelect,
            }, this.projId).subscribe(
                response => {
                    let desc: string;
                    this.tihiProjDescription = '';
                    this.reason = 'node update';

                    if (this.projectDescription != this.projectDescriptionOld) {
                        desc = "'Description' changed from [" + this.projectDescriptionOld + "] to [" + this.projectDescription + "]";
                        
                        if(this.tihiProjDescription != ''){
                        this.tihiProjDescription += ", "
                        }
                        this.tihiProjDescription += desc;
                    }
                
                    if (this.projectName != this.projectNameOld) {
                        desc = "'Name' changed from [" + this.projectNameOld + "] to [" + this.projectName + "]";
                        
                        if(this.tihiProjDescription != ''){
                        this.tihiProjDescription += ", "
                        }
                        this.tihiProjDescription += desc;
                    }

                    if (this.value != this.valueOld) {
                        desc = "'Value' changed from " + this.valueOld + " to " + this.value;
                        
                        if(this.tihiProjDescription != ''){
                        this.tihiProjDescription += ", "
                        }
                        this.tihiProjDescription += desc;
                    }


                    if (this.customerSelect != this.customerSelectOld) { 
                        let customerName = '';
                        let customerNameOld 
                            for(let customer of this.customers){
                                if(customer.id == this.customerSelect){
                                    customerName = customer.name;
                                }
                                if(customer.id == this.customerSelectOld){
                                    customerNameOld = customer.name;
                                }
                                desc = "'Customer' changed from [" + customerNameOld + "] to [" + customerName + "]";
                            }
                            if(this.tihiProjDescription != ''){
                            this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                    }
                    

                    if (this.statusSelect != this.statusSelectOld) {
                        let statusName = '';
                        let statusNameOld 
                            for(let status of this.statuss){
                                if(status.id == this.statusSelect){
                                    statusName = status.name;
                                }
                                if(status.id == this.statusSelectOld){
                                    statusNameOld = status.name;
                                }
                                desc = "'Status' changed from [" + statusNameOld + "] to [" + statusName + "]";
                            }
                            if(statusName == 'Closed'){
                                this.closeProjectAndTasks()
                            }
                            if(this.tihiProjDescription != ''){
                            this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                        }

                    if (this.userSelect != this.userSelectOld) { 
                        let userName = '';
                        let userNameOld = '';
                        for(let user of this.activeUsers){
                            if(user.id == this.userSelect){
                                userName = user.fullName;
                            }
                            if(user.id == this.userSelectOld){
                                userNameOld = user.fullName;
                            }
                            desc = "'Project Manager' changed from [" + userNameOld + "] to [" + userName + "]";
                        }
                        if(this.tihiProjDescription != ''){
                        this.tihiProjDescription += ", "
                        }
                        this.tihiProjDescription += desc;
                    }

                    if (this.teamSelect != this.teamSelectOld) { 
                        let teamName = '';
                        let teamNameOld = '';
                        for(let user of this.teams){
                            if(user.id == this.teamSelect){
                                teamName = user.name;
                            }
                            if(user.id == this.teamSelectOld){
                                teamNameOld = user.name;
                            }
                            desc = "'Team' changed from [" + teamNameOld + "] to [" + teamName + "]";
                        }
                        if(this.tihiProjDescription != ''){
                        this.tihiProjDescription += ", "
                        }
                        this.tihiProjDescription += desc;
                    }

                    if((this.modelStartOld != null && this.modelStartOld != undefined)&&(this.modelStart != null && this.modelStart != undefined)){
                        if ((this.modelStart.day != this.modelStartOld.day)||
                        (this.modelStart.month != this.modelStartOld.month)||
                        (this.modelStart.year != this.modelStartOld.year)) {
                            desc = "'Expected Start' changed from [" + this.dateObjectToString(this.modelStartOld) + "] to [" + this.dateObjectToString(this.modelStart) + "]";
                            if(this.tihiProjDescription != ''){
                                this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                        }
                    }else{
                        if(this.modelStartOld == null && this.modelStart != null){
                            desc = "'Expected Start' changed from [] to [" + this.dateObjectToString(this.modelStart) + "]";
                            if(this.tihiProjDescription != ''){
                                this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                        }

                        if(this.modelStart == null && this.modelStartOld != null){
                            desc = "'Expected Start' changed from [" + this.dateObjectToString(this.modelStartOld) + "] to []";
                            if(this.tihiProjDescription != ''){
                                this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                        }
                    }
                    
                    if((this.modelFinishOld != null && this.modelFinishOld != undefined)&&(this.modelFinish != null && this.modelFinish != undefined)){

                        if ((this.modelFinish.day != this.modelFinishOld.day)||
                        (this.modelFinish.month != this.modelFinishOld.month)||
                        (this.modelFinish.year != this.modelFinishOld.year)) {
                            desc = "'Expected Finish' changed from [" + this.dateObjectToString(this.modelFinishOld) + "] to [" + this.dateObjectToString(this.modelFinish) + "]";
                            if(this.tihiProjDescription != ''){
                                this.tihiProjDescription += ", "
                            }
                                    this.tihiProjDescription += desc;
                        }
                    }else{
                        if(this.modelFinishOld == null && this.modelFinish != null){
                            desc = "'Expected Finish' changed from [] to [" + this.dateObjectToString(this.modelFinish) + "]";
                            if(this.tihiProjDescription != ''){
                                this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                        }

                        if(this.modelFinish == null && this.modelFinishOld != null){
                            desc = "'Expected Finish' changed from [" + this.dateObjectToString(this.modelFinishOld) + "] to []";
                            if(this.tihiProjDescription != ''){
                                this.tihiProjDescription += ", "
                            }
                            this.tihiProjDescription += desc;
                        }
                    }
                
                    alert('Project ' + this.projId + ' is saved.');
                    if (this.tihiProjDescription != "") {
                        this.createProjectHistory('UPDATE');
                    }
                    this.getProject(this.projId);
                    this.close();
                    },
                    err => {
                        alert(err.error.errorMessage);  
                        this.ttService.checkErr(err, 'error update ticket', this.modalRef);
                        this.alertsDismiss.push({
                            type: 'danger',
                            msg: err.error.errorMessage,
                            timeout: 5000
                        });
                    });
                } else {
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: "The following field(s) is(are) invalid: ",
                        timeout: 5000
                    });
                    for(let errorMessage of invalidDates) {
                        this.alertsDismiss.push({
                            type: 'danger',
                            msg: errorMessage,
                            timeout: 5000
                        });
                    }
                    alert("The following field(s) is(are) invalid: " + invalidDates.join(""));
                }
            }

    deleteProject(project) {
        if (confirm("Are you sure you want to delete " + project[0].name + "?")) {
            this.busy = this.ttService.deleteProject(project[0].id).subscribe(
                response => {
                    this.reason = 'project delete';
                    alert('Project #' + this.projectId + ' deleted.');
                    this.close();
                },
                err => {
                    this.ttService.checkErr(err, 'error delete project', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });

                }
            );
        }
    }

    onChangeCustomer(event) {
        this.departments = [];
        this.getDepartments();
    }

    onChangeTeam(event){
        this.getUsers(event.target.value);  
    }

    close() {
        this.modalClose.close(this.reason);
    }

    closeProjectAndTasks(){
        this.ttService.closeTasksByProject(this.projId, sessionStorage.getItem('userId')).subscribe();
    }

    progressByUsage(idProject, idCustomer) {
        this.modalClose.close('');
        this.ttService.redirectToProgressByUsage(idProject, idCustomer);
    }

    timeTrackingAnalytic(idProject, idCustomer) {
        this.modalClose.close('');
        this.ttService.redirectToTimeTrackingAnalytic(idProject, idCustomer, null);
    }
    
    /*addtask(){
        let modalDialog = this.ngbModal.open(TaskDialogComponent, { size: 'lg' });      
        modalDialog.componentInstance.projectId = this.projId;
        modalDialog.componentInstance.customerId = this.custId;
    }*/

    openAttachments(project) {
        let modal = this.ngbModal.open(AttachmentsDialogComponent, { size: 'lg', backdrop: 'static', keyboard : false });
        
        modal.componentInstance.tableId = this.projId;
        modal.componentInstance.tableName = 'project'
        modal.componentInstance.enableUpload = true;

        modal.result.then(response => {
            for(let fileLog in response){
              this.tihiProjDescription = response[fileLog].description;
              this.createProjectHistory(response[fileLog].action);
            }      
            if(this.event){
              this.getProjectsHistory(this.projId+'?page='+this.event.page+'&size=10');
            }else{
              this.getProjectsHistory(this.projId+'?page=&size=10');
            }
        });
    }
      
    paginate(event) {
        //event.first = Index of the first record
        //event.rows = Number of rows to display in new page
        //event.page = Index of the new page
        //event.pageCount = Total number of pages
        this.event = event;
        this.getProjectsHistory(this.projId+'?page='+event.page+'&size=10');
    }

    open(template: TemplateRef<any>, ticket, size) {
        this.newVersionModalRef = this.ngbModal.open(template, size);
    }

    dateObjectToString(date){
        return date.year + "-" + ("0" + date.month).slice(-2) + "-" + ("0" + date.day).slice(-2);
    }

    checkTask(){
        let taskFilter = {
            "project": [{"id": this.projId}],
            "company": {"id": sessionStorage.getItem('compId')}
        };
        this.busy = this.ttService.getTasksBySpecification(taskFilter, null, null).subscribe(
            data => {
                this.containsTask = data != null ? true : false;
            }
        );
    }

    checkMilestone(){
        this.busy = this.ttService.getPhasesByProject(this.projId).subscribe(
            data => {
                this.containsPhase = data != null ? true : false;
            }
        );
    }

    createTemplateRedirect(){
        this.busy = this.ttService.createTemplate(this.projId).subscribe(
          response => {
            alert("Default WBS is created.");
            this.ttService.redirectToProgressByUsage(this.projId, this.customerSelect);
            this.close();
          },
          err => {
            alert(err.error.errorMessage);
            this.ttService.checkErr(err, 'error create project template', this.modalRef);
            this.alertsDismiss.push({
              type: 'danger',
              msg: err.error.errorMessage,
              timeout: 5000
            });
          },
          () => console.log('error create project template')
        ); 
      }

    createTemplate(){
        let projectDialogComponent = this;
        let modalDialog = this.ngbModal.open(ConfirmBoxDialogComponent, { backdrop: 'static', keyboard : false });
        modalDialog.componentInstance.titleHeader = "Default WBS creation";
        modalDialog.componentInstance.bodyMessage = "Do you want to create a default WBS? This functionality is irreversible.";
        modalDialog.componentInstance.buttons = [
            {
                "type": "button",
                "className": "btn btn-primary",
                "text": "Yes",
                "action": function() { modalDialog.componentInstance.close("YES"); }
            }, {
                "type": "button",
                "className": "btn btn-outline-dark",
                "text": "No",
                "action": function() { modalDialog.componentInstance.close("NO"); }
            }
        ];
        modalDialog.result.then(resolve => {
            if(resolve === "YES") {
                projectDialogComponent.reason = 'node update';
                projectDialogComponent.createTemplateRedirect();
            } else {
                projectDialogComponent.ttService.redirectToProgressByUsage(this.projId, this.customerSelect);
                projectDialogComponent.close();
            }
        });
    }

    validateDates(date){  
        //Parametros para validação.
        let regex = new RegExp('^([0-9]{4}-[0-9]{2}-[0-9]{2})$', 'g');
        let isValid = true;
        //Mensagens de erro.
        let invalidFormat = "Invalid date format."
        let invalidRange = "Invalid date range."
        this.dateError = null;
        //Caso a data seja inválida ela se tornará uma string.
        if(typeof date === "string"){
            //Caso a string tenha o formato correto, o erro é de range.
            if(regex.test(date)){
            isValid = false;
            this.dateError = invalidRange;
            //Caso a string não tenha o formato correto, o erro é de formato.
            } else{
            isValid = false;
            this.dateError = invalidFormat;
            }
        //Caso a data seja do tipo date ela deverá ficar entre os anos de 1969 e 2099.
        } else if(date.year < 1969 || date.year > 2099){
            //Se a data possuir 4 digitos, o erro é de range.
            if(date.year.toString().length == 4){
            this.dateError = invalidRange;
            //Se a data possuir mais ou menos que 4 digitos, o erro é de formato.
            } else{
            this.dateError = invalidFormat;
            }
            isValid = false;      
        }
        return isValid;
    }
}