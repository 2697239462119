import { Route } from '@angular/compiler/src/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "./../../../services/auth.service";
import { TimeTrackingService } from "./../../../services/time-tracking.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-feedback-poll",
  templateUrl: "./feedback-poll.component.html",
  styleUrls: ["./feedback-poll.component.scss"],
})
export class FeedbackPollComponent implements OnInit {
  itemForm: FormGroup = this.fb.group({
    firstQuestion: ["", Validators.required],
    secondQuestion: ["", Validators.required],
    thirdQuestion: ["", Validators.required],
    ticket: [0],
  });

  searched = false;
  description: any;
  id: any;
  delivered = false;

  isGuest = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    private ngbModal: NgbModal,
    private ttService: TimeTrackingService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {

    if(sessionStorage.getItem("base64Credential") == null) {
      this.isGuest = true;
    }

    this.route.params.subscribe({
      next: (params) => {
        this.itemForm.patchValue({
          ticket: Number.parseFloat(params["id"]),
        });
        console.log(this.itemForm.value.ticket)
        this.find(this.itemForm.value.ticket);
      },
    });
  }

  find(ticket: any){

    this.ttService.findTicket(ticket).subscribe((response) => {
      this.id = ticket
      this.description = response.shortDescription
      console.log(response)
    })

    this.ttService.findAnswer(ticket).subscribe({
      next: response => {
        this.itemForm.patchValue(response.ticketQuestions[0]);
        this.searched = true;
      },
      error: () => {
        this.searched = false
      },
    });

  }

  sendForm() {
    this.ttService.sendForm(this.itemForm.value).subscribe((response) => {
      this.delivered = true;
      alert("Resposta enviada. \nAgradecemos sua contribuição!");
      window.close();
    });
  }

  navigate(id: any) {
    this.router.navigate(["/management/feedback"]);
  }

}
