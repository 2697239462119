import { AttachmentsDialogComponent } from './../attachments-dialog/attachments-dialog.component';
import { TimeSheetDialogComponent } from './../timesheet-dialog/timesheet-dialog.component';
import { Subscription } from 'rxjs';
import { Input } from '@angular/core';
import { TimeTrackingService } from './../../../services/time-tracking.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import "bootbox";
import { SlaDialogComponent } from '../sla-dialog/sla-dialog.component';

@Component({
  selector: 'system-dialog',
  templateUrl: './system-dialog.component.html'
})
export class SystemDialogComponent implements OnInit {
  @Input() systemId:      any;
  @Input() contractId:    any;
  
  public contracts;
  public systemsWithoutSla;
  public companyId = sessionStorage.getItem('compId');

  newVersionModalRef:     NgbModalRef;
  logDescModalRef:        NgbModalRef;
  busy:                   Subscription;
  values = new Array();
  
  titleHeader:            string;
  logTitle:               string;
  fullLog:                any;
  nameSelected:           string;
  syhiDescription:        string;

  disableContract:        boolean;
  showSystemHistory:      boolean;
  buttonAction:           boolean;

  contract:               any;
  system:                 any;
  systemHistories:        any;
  contractSelected:       any;
  contractName:           any;
  syhiCols:               any;
  nameSelectedOld:        any;
  contractSelectedOld:    any;
  contractNameOld:        any;
  event:                  any;

  totalPages          : number;
  totalElements       : number;
  
  alertsDismiss:          any[] = [];
  logDescription:         any[] = [];
  
  historyDate:            Date;

  constructor(private ttService: TimeTrackingService, private ngbModal: NgbModal, private modalRef: NgbActiveModal){}
  
  ngOnInit() {

    this.titleHeader = 'New System';  

    this.buttonAction = false;
    this.showSystemHistory = false;
    
    this.alertsDismiss = [];
    this.nameSelected = '';
    this.syhiDescription = '';
    var profile = sessionStorage.getItem('userProfile');
    
    this.getContracts();
    
    if(this.contractId){
      this.contractSelected = this.contractId;
      this.disableContract = true;
    }

    if (this.systemId) {

      this.buttonAction = true;
      this.showSystemHistory = true;
  
      this.getSystem(this.systemId);

      this.syhiCols = [
        //{ field: 'id',     header: 'History ID' },
        { field: 'date',       header: 'Creation Date' },
        { field: 'userName',   header: 'User' },
        { field: 'action', header: 'Action' },
        { field: 'description',   header: 'Description' },
        
        ];  
    }
  
  }

  getSystem(systemId) {
    this.busy = this.ttService.getSystem(systemId).subscribe(
      data => {
        this.fillSystem(data)
      }
    );
  }

getContracts() {
  this.busy = this.ttService.getActiveContracts(sessionStorage.getItem("compId")).subscribe(
    data => { 
      this.contracts = data["contracts"];   
    },
    err => {
      this.ttService.checkErr(err, 'error loading contracts', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.error.errorMessage,
        timeout: 5000
      });

    },
    () => console.log('done loading contracts')
  );
}

getSystemHistory(systemId) {
  this.busy = this.ttService.getSystemHistory(systemId).subscribe(
    data => { 
      this.systemHistories = data["systems"];
      for(let history of this.systemHistories){
        history["userName"] = history.author.fullName;

        this.historyDate = new Date();
        this.historyDate.setTime(history.dateCreated);
        history["date"] = this.historyDate.getFullYear() + "-" + this.historyDate.getMonth() + "-" + ("0" + this.historyDate.getDate()).slice(-2)+ " - " + ("0" + this.historyDate.getHours()).slice(-2) + ":" + ("0" + this.historyDate.getMinutes()).slice(-2);
      }

      this.totalPages = data["pageNumber"];
      this.totalElements = data["elementNumber"];
      this.fullLog = this.systemHistories;
    });
}

fillSystem(system) {
  this.system = system;

  if(this.event){
    this.getSystemHistory(this.system.id+'?page='+this.event.page+'&size=10');
  }else{
    this.getSystemHistory(this.system.id+'?page='+ 0 +'&size=10');
  }

  this.nameSelected = system.name;
  this.nameSelectedOld = system.name;

  this.contractNameOld = "";
  this.contractName = "";
  if(system.contract){
    this.contractSelected = system.contract.id;
    this.contractSelectedOld = system.contract.id;
    this.contractNameOld = system.contract.name;
  }
  

  if(this.systemId) {
    this.titleHeader = 'View System # ' + this.systemId;
  }
}


saveSystem(){
  if(!this.systemId || this.systemId == 0) {
    this.createSystem();
  } else {
    this.updateSystem();
  }
}

createSystem() {
 
  this.contract = {"id" : this.contractSelected};
  if(this.contractSelected == ""){
    this.contract = null;
  }
   
  this.busy = this.ttService.createSystem({
    "name"                : this.nameSelected,
    "contract"            : this.contract,
    "activated"           : true,
    "company"             : {'id' : sessionStorage.getItem('compId')}
  }).subscribe(
    response => {
      this.systemId = response["id"];
      this.syhiDescription = 'Created System';
      this.createSystemHistory('INSERT');
      alert('System ' + this.nameSelected + ' is saved!');
      this.close();
    },
    err => {
      this.ttService.checkErr(err,'error create system', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.errorerrorMessage,
        timeout: 5000
      });
      
    });
}

updateSystem() {
  
  this.contract = {"id" : this.contractSelected};
  if(this.contractSelected == ""){
    this.contract = null;
  }

  this.busy = this.ttService.updateSystem({
    "name"                  : this.nameSelected,
    "contract"              : this.contract,
    "activated"             : true,
    "company"               : {'id' : sessionStorage.getItem('compId')}
  }, this.systemId).subscribe(
    response => {
      let desc: string = '';
      this.syhiDescription = '';

      if (this.nameSelected != this.nameSelectedOld) {
        desc = "'Name' changed from [" + this.nameSelectedOld + "] to [" + this.nameSelected + "]";
        if(this.syhiDescription != ''){
          this.syhiDescription += ", "
        }
        this.syhiDescription += desc;
      }

      if (this.contractSelected != this.contractSelectedOld) {
        if(this.contractSelected == null)this.contractSelected = "";
        for(let contract of this.contracts){
          if(contract["id"] == this.contractSelected){
            this.contractName = contract["name"];
          }
        }
        desc = "'Contract' changed from [" + this.contractNameOld + "] to [" + this.contractName + "]";
        if(this.syhiDescription != ''){
          this.syhiDescription += ", "
        }
        this.syhiDescription += desc;
      }
      alert('System ' + this.systemId + ' is saved.');
      if (this.syhiDescription != "") {
        this.createSystemHistory('UPDATE');
      }
      this.fillSystem(response);
    },
    err => {
      this.ttService.checkErr(err, 'error update system', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.error.errorMessage,
        timeout: 5000
      });
    });
}

createSystemHistory(action) {
  this.ttService.createSystemHistory({
    'action': action,
    'description': this.syhiDescription
  },this.systemId, sessionStorage.getItem('userId')).subscribe(
    response => {
      this.syhiDescription = '';
      this.values = [];
      if(this.event){
        this.getSystemHistory(this.systemId+'?page='+this.event.page+'&size=10');
      }else{
        this.getSystemHistory(this.systemId+'?page='+ 0 +'&size=10');
      }
    },
    err => {
      this.ttService.checkErr(err, 'error loading system history', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.error.errorMessage,
        timeout: 5000
      });
    },
  );
}

openComment(template: TemplateRef<any>, task) {
  this.newVersionModalRef = this.ngbModal.open(template, {backdrop: 'static'});
 }

 openHistory(template: TemplateRef<any>, row) { 
  this.logTitle = row.id + " - " + row.action;
  this.populationViewLog(row);
  this.logDescModalRef = this.ngbModal.open(template);
}
 
 saveComment(){
   this.createSystemHistory('COMMENT');
   this.newVersionModalRef.close('')
 }

 editSla(){
  this.busy = this.ttService.getSystemsWithoutSla(this.companyId).subscribe(
    data => {
        let hasSla = true;
        this.systemsWithoutSla = data["systems"];
        for(let system of this.systemsWithoutSla){
          if(this.system.id == system.id){
            hasSla = false;
          }              
        }       
        if(hasSla){
          let modalDialog = this.ngbModal.open(SlaDialogComponent, { size: 'lg' });
          modalDialog.componentInstance.systemId = this.system.id;
          modalDialog.componentInstance.type = 'update';
        }else{
          let modalDialog = this.ngbModal.open(SlaDialogComponent, { size: 'lg' });
          modalDialog.componentInstance.systemId = this.system.id;
          modalDialog.componentInstance.type = 'create';
        }
      },
      err => {
        this.ttService.checkErr(err, 'error loading systems without sla', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });

      },
      () => console.log('done loading systems without sla')
    );
 }

close() {
    this.modalRef.close('');
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.event = event;
    console.log("systemID= "+ this.systemId);
    this.getSystemHistory(this.systemId+'?page='+event.page+'&size=10');
  }

  open (template, options) {
    this.newVersionModalRef = this.ngbModal.open(template, options);
  }

  populationViewLog(row) {
    this.logDescription = new Array();
    let positions = new Array();
    if(row.action == "UPDATE"){
      positions.push(parseInt(row.description.indexOf("'Name'"),10)); 
      positions.push(parseInt(row.description.indexOf("'Contract'"),10)); 
      positions.push(row.description.length); 
      positions.sort(function(a, b) { return a - b; });

      for(let position in positions){
        let aux = parseInt(position, 10);
        if(positions[aux] != -1 && aux != positions.length-1){
          // The last vector it is not necessary to remove the commas
          if(aux == positions.length-2){
             // Business rule to show a maximum of 300 characters
             if((positions[aux+1] - positions[aux])>300){
               this.logDescription.push(row.description.substring(positions[aux], positions[aux]+300)+'...');
             }else{
               this.logDescription.push(row.description.substring(positions[aux], positions[aux+1]));
             }
          // Remove the commas
          }else{
            // Business rule to show a maximum of 300 characters
            if((positions[aux+1] - positions[aux])>300){
              this.logDescription.push(row.description.substring(positions[aux], positions[aux]+300)+'...');
            }else{
              this.logDescription.push(row.description.substring(positions[aux], positions[aux+1]-2));
            }
          }
        }
      }
    }else{
        this.logDescription.push(row.description);
    }
  }

}
