import { Component, OnInit }   from '@angular/core';
import { Router }              from '@angular/router';
import { NgbActiveModal }      from '@ng-bootstrap/ng-bootstrap';
import { TimeTrackingService } from './../../../services/time-tracking.service';

@Component({
    selector    : 'app-userchangepassword-dialog',
    templateUrl : './userchangepassword-dialog.component.html'
})
export class UserChangePasswordComponent implements OnInit {
    public userId          : string;
    public currentPassword : string;
    public newPassword     : string;
    public verifyPassword  : string;
    public currentUser     : any;
    public alertsDismiss   : any = [];

    constructor(private router: Router
        , private overlay: NgbActiveModal
        , private service: TimeTrackingService) { }

    ngOnInit(): void {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.userId      = this.currentUser.id;
    }

    close() {
        this.overlay.close('');
    }

    save() {
        this.service.changePassword({
            'newPassword'    : this.newPassword,
            'verifyPassword' : this.verifyPassword
        }, this.userId).subscribe(
            response => {
                alert('Your password has been successfully changed!');
                this.overlay.close('');
                sessionStorage.clear();
                this.router.navigateByUrl('/login');
                location.reload();
            },
            error => {
                console.log('Error from UserChangePasswordComponent', error.error);
                this.alertsDismiss.push({
                    type    : 'danger',
                    msg     : error.error,
                    timeout : 5000
                  });
            });
    }
}