import { FeedbackPollComponent } from './views/management/feedback-poll/feedback-poll.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UrlPermission } from "./urlPermission/url.permission";

// Import Containers
import { FullLayoutComponent, SimpleLayoutComponent } from "./containers";

export const routes: Routes = [
  {
    path: "feedback-pool/:id",
    component: FeedbackPollComponent,
  },
  {
    path: "home",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [UrlPermission],
    data: {
      title: "Home",
    },
    children: [
      {
        path: "admin",
        loadChildren: "./views/admin/admin.module#AdminModule",
      },
      {
        path: "configuration",
        loadChildren:
          "./views/configuration/configuration.module#ConfigurationModule",
      },
      {
        path: "management",
        loadChildren: "./views/management/management.module#ManagementModule",
      },
      {
        path: "report",
        loadChildren: "./views/report/report.module#ReportModule",
      },
      {
        path: "view",
        loadChildren: "./views/view/view.module#ViewModule",
      },
      {
        path: "dashboard",
        loadChildren: "./views/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "demo",
        loadChildren: "./demo/demo.module#DemoModule",
      }
    ],
  },
  {
    path: "",
    component: SimpleLayoutComponent,
    data: {
      title: "Pages",
    },
    children: [
      {
        path: "login",
        loadChildren: "./views/pages/pages.module#PagesModule",
      },
      {
        path: "reset-password",
        loadChildren: "./views/pages/reset.module#ResetModule",
      },
      {
        path: "ticket",
        loadChildren: "./views/pages/my-ticket.module#MyTicketModule",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
