import { MatFormFieldModule } from '@angular/material';
import { busyConfig } from './../utils/busy.config';
import { NgBusyModule } from 'ng-busy';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { TicketDialogComponent } from './ticket-dialog/ticket-dialog.component';
import { ProjectDialogComponent } from './project-dialog/project-dialog.component';
import { SystemDialogComponent } from './system-dialog/system-dialog.component';
import { ContractDialogComponent } from './contract-dialog/contract-dialog.component';
import { MilestoneDialogComponent } from './milestone-dialog/milestone-dialog.component';
// Angular
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { FocusModule } from 'angular2-focus';

/** third party */
import { TableModule } from 'primeng/table';
import { ModalModule, AlertModule } from 'ngx-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from 'primeng/spinner';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { GrowlModule } from 'primeng/growl';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { UtilsModule } from '../utils/module';

import { UrlUserPermission } from '../../urlPermission/urluser.permission';
import { UrlManagerPermission } from '../../urlPermission/urlmanager.permission';
import { UrlManagerUserPermission } from '../../urlPermission/urlmanageruser.permission';
import { TimeSheetDialogComponent } from './timesheet-dialog/timesheet-dialog.component';
import { AttachmentsDialogComponent } from './attachments-dialog/attachments-dialog.component';
import { SlaDialogComponent } from './sla-dialog/sla-dialog.component';
import { CustomerDialogComponent } from './customer-dialog/customer-dialog.component';
import { PaginatorModule } from 'primeng/paginator';
import { OrderModule } from 'ngx-order-pipe';
import { UserChangePasswordComponent } from './user-dialog/userchangepassword-dialog.component';
import { ConfirmBoxDialogComponent } from './confirm-box-dialog/confirm-box-dialog.component';
import { ProjectCostDialogComponent } from './project-cost-dialog/project-cost-dialog.component';
import { TabViewModule } from 'primeng/tabview';
import { CostDialogComponent } from './cost-dialog/cost-dialog.component';
import { BillingDialogComponent } from './billing-dialog/billing-dialog.component';
import {AccordionModule} from 'primeng/accordion';
import { ProjectFollowUpComponent } from './project-follow-up/project-follow-up.component';
import { FollowUpDialogComponent } from './follow-up-dialog/follow-up-dialog.component';
import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';
import { NgxCurrencyModule } from "ngx-currency";
import {TextFieldModule} from '@angular/cdk/text-field';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';

registerLocaleData(localePT, 'pt', localeExtraPT);
export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ".",
    nullable: false
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    TableModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ModalModule.forRoot(),
    TextMaskModule,
    NgbModule.forRoot(),
    SpinnerModule,
    DataViewModule,
    PanelModule,
    FocusModule.forRoot(),
    GrowlModule,
    AlertModule.forRoot(),
    PaginatorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    UtilsModule,
    NgBusyModule.forRoot(busyConfig),
    NgxMaskModule.forRoot(),
    OrderModule,
    TabViewModule,
    AccordionModule,
    TextFieldModule,
  ],

  declarations: [
    TaskDialogComponent,
    TicketDialogComponent,
    SystemDialogComponent,
    SlaDialogComponent,
    ContractDialogComponent,
    CustomerDialogComponent,
    ProjectDialogComponent,
    TimeSheetDialogComponent,
    UserChangePasswordComponent,
    AttachmentsDialogComponent,
    MilestoneDialogComponent,
    ConfirmBoxDialogComponent,
    ProjectCostDialogComponent,
    CostDialogComponent,
    BillingDialogComponent,
    ProjectFollowUpComponent,
    FollowUpDialogComponent,
    FeedbackDialogComponent,
  ],

  exports: [PaginatorModule, TabViewModule],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [UrlManagerPermission, UrlManagerUserPermission, {provide: 'pt', useValue: 'pt'}],

  entryComponents: [
    ProjectDialogComponent,
    TimeSheetDialogComponent,
    UserChangePasswordComponent,
    TaskDialogComponent,
    TicketDialogComponent,
    SystemDialogComponent,
    SlaDialogComponent,
    ContractDialogComponent,
    CustomerDialogComponent,
    MilestoneDialogComponent,
    AttachmentsDialogComponent,
    ConfirmBoxDialogComponent,
    CostDialogComponent,
    BillingDialogComponent,
    FollowUpDialogComponent,
    FeedbackDialogComponent
  ]
})
export class DialogsModule { }
