import { Status } from './model.status';
import { Customer } from './model.customer';
import { Company } from './model.company';
import { Project } from './model.project';
import { Phase } from './model.phase';
import { PredecessorTask } from './model.predecessorTask';
import { User } from './model.user';
import { Team } from './model.team';

export class Task {
    id: number = 0;
    name: string = "";
    status: Status = new Status;
    company: Company = new Company;
    customer: Customer = new Customer;
    project: Project = new Project;
    phase: Phase = new Phase;
    description: string = "";
    predecessor: PredecessorTask = new PredecessorTask;
    effort: number = 0;
    expectedStart: {year: number, month: number, day: number} = null;
    expectedFinish: {year: number, month: number, day: number} = null;
    expectedStartDate: Date = new Date();
    expectedFinishDate: Date = null;
    team: Team = new Team;
    responsible: User = new User;
    creationUser: User = new User;
    updateUser: User = null;
}