import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../model/model.user';

@Injectable()
export class UrlManagerUserPermission implements CanActivate {
  user: User;
  constructor() {
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('withCredentials') == 'true') {
    if(this.user.profile == 'MANAGER' ||
       this.user.profile == 'ANALYST' ||
       this.user.profile == 'ADMIN'   ||
       this.user.profile == 'USER'    ||
       this.user.profile == 'TICKET_USER') {
      return true;
    }
  }

    return false;
  }
}
