export class User {
  id : string = '';
  username: string="";
  password: string="";
  passwordText: string="";
  actualPasswordText: string="";
  passwordConfirmText: string="";
  fullName: string="";
  profile: string="";
  compName: string="";
  companyId: string;
  statusId: string;
}
