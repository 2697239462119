import { FeedbackPollComponent } from './views/management/feedback-poll/feedback-poll.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TreeModule } from 'angular-tree-component';
import { NgBusyModule } from 'ng-busy';
import { AvatarModule } from 'ng2-avatar';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule, BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { GrowlModule } from 'primeng/growl';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { FullLayoutComponent, SimpleLayoutComponent } from './containers';
import { AccountService } from './services/account.service';
import { AuthService } from './services/auth.service';
import { TimeTrackingService } from './services/time-tracking.service';
import { UrlPermission } from './urlPermission/url.permission';
import { DialogsModule } from './views/dialogs/dialogs.module';
import { busyConfig } from './views/utils/busy.config';
import { CustomBusyComponent } from './views/utils/custom.busy.component.';
import { NgGanttEditorModule } from 'ng-gantt';
import { NgxCurrencyModule } from "ngx-currency";
import { MatFormFieldModule, MatIconModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
const APP_CONTAINERS = [
  FullLayoutComponent,
  SimpleLayoutComponent
]
@NgModule({ 
  imports: [
    BrowserModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgBusyModule.forRoot(busyConfig),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    NgxCurrencyModule,
    MatRadioModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    //CalendarModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,

    DialogsModule,

    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    AvatarModule.forRoot(),
    GrowlModule,
    TreeModule,
    NgGanttEditorModule,
    ReactiveFormsModule
  ],

  declarations: [
    AppComponent,
    CustomBusyComponent,
    FeedbackPollComponent,
    ...APP_CONTAINERS,
  ],
  providers: [TimeTrackingService, AuthService, AccountService,UrlPermission,{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  entryComponents: [ CustomBusyComponent],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
