import { Component, OnInit, Injectable, Input, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimeTrackingService } from '../../../services/time-tracking.service';
import { Task } from '../../../model/model.task';
import { Status } from '../../../model/model.status';
import { Customer } from '../../../model/model.customer';
import { Project } from '../../../model/model.project';
import { Phase } from '../../../model/model.phase';
import { Team } from '../../../model/model.team';
import { User } from '../../../model/model.user';
import { AlertDismiss } from '../../../model/model.alertDismiss';
import { TableColumn } from '../../../model/model.tableColumn';
import { TaskProgress } from '../../../model/model.taskProgress';
import { PredecessorTask } from '../../../model/model.predecessorTask';
import { TaskHistory } from '../../../model/model.taskHistory';
import { NgbActiveModal, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeSheetDialogComponent } from '../timesheet-dialog/timesheet-dialog.component';
import { AttachmentsDialogComponent } from '../attachments-dialog/attachments-dialog.component';

@Component({
    selector: 'task-dialog',
    templateUrl: './task-dialog.component.html'
})
@Injectable()
export class TaskDialogComponent implements OnInit {
    busy: Subscription;

    constructor(private ttService: TimeTrackingService, private modalRef: NgbActiveModal, private ngbModal: NgbModal) { }

    @Input() taskId: number;
    @Input() projectId: number;
    @Input() customerId: number;
    @Input() phaseId: number;

    titleHeader: string = 'New Task';
    dateError: string;
    alertsDismiss: AlertDismiss[] = [];
    showTaskHistory: boolean = false;
    disableAll: boolean = false;
    allowTimesheet: boolean = false;
    enableCustomer: boolean = true;
    enableProject: boolean = false;
    enablePhase: boolean = false;
    enablePredecessor: boolean = false;
    enableResponsible: boolean = false;
    isAdmin: boolean;
    isManager: boolean;
    reason: string;

    task: Task = new Task;
    taskOld: Task;
    progress: TaskProgress = new TaskProgress;
    predecessors: PredecessorTask[] = [];
    statuses: Status[] = [];
    customers: Customer[] = [];
    projects: Project[] = [];
    phases: Phase[] = [];
    teams: Team[] = [];
    responsibles: User[] = [];

    logTitle: string;
    logDescription: string[];
    taskHistories: TaskHistory[];
    taskHistoryDescription: string = '';
    totalElements: number;
    totalPages: number;
    generalModalRef: NgbModalRef;
    logModalRef: NgbModalRef;
    taskHistoryColumns: TableColumn[] = [
        { field: 'dateCreatedString', header: 'Date' },
        { field: 'userFullName', header: 'User' },
        { field: 'action', header: 'Action' },
        { field: 'description', header: 'Description' }
    ];
    event;

    ngOnInit() {
        sessionStorage.setItem('padoName', 'TASK');
        sessionStorage.setItem('paraName', 'TASK_CLOSED,TASK_OPENED');
        this.isAdmin = sessionStorage.getItem('userProfile') == 'ADMIN' ? true : false;
        this.isManager = sessionStorage.getItem('userProfile') == 'MANAGER' ? true : false;
        
        if (this.taskId) {
            this.titleHeader = "Edit Task #" + this.taskId;
            this.enableCustomer = false;
            this.enableResponsible = true;
            this.enablePredecessor = true;
            this.showTaskHistory = true;
            this.getTaskById(this.taskId);
        } else {
            if (this.customerId != null) {
                this.enableCustomer = false;
                this.ttService.getCustomersById(this.customerId).subscribe(
                    data => {
                        let response: any = data;
                        for (let customer of response) {
                            this.customers.push({ 'custId': customer.id, 'custName': customer.name });
                            this.task.customer.custId = customer.id;
                        }
                    }
                );
            } else this.getCustomersByCompany(+sessionStorage.getItem('compId'));

            if(this.projectId != null) {
                this.ttService.getProjectsById(this.projectId).subscribe(
                    data => {
                        let response: any = data;
                        for (let project of response) {
                            this.projects.push(Object.assign({}, project));
                            this.task.project.id = project.id;
                            this.enablePredecessor = true;
                            this.getPredecessorsByProject(project.companyId, project.id);
                        }
                    }
                );
            } else this.enableProject = true;

            if(this.phaseId != null) {
                this.ttService.getPhaseById(this.phaseId).subscribe(
                    data => {
                        this.phases.push(<Phase>data);
                        this.task.phase.id = data['id'];
                    }
                );
            } else this.enablePhase = true;
        }
        this.getStatus(+sessionStorage.getItem('compId'), sessionStorage.getItem('paraName'));
        this.getTeams(+sessionStorage.getItem('compId'));
    }

    getStatus(companyId: number, parameterName: string) {
        this.ttService.getStatusByParameters(companyId, parameterName).subscribe(
            data => {
                let response: any = data;
                for (let status of response) {
                    status.sequence = status.name == 'Planned' && (status.sequence == null || status.sequence == 0) ? 1 : status.sequence;
                    status.sequence = status.name == 'In Progress' && (status.sequence == null || status.sequence == 0) ? 2 : status.sequence;
                    status.sequence = status.name == 'On Hold' && (status.sequence == null || status.sequence == 0) ? 3 : status.sequence;
                    status.sequence = status.name == 'Closed' && (status.sequence == null || status.sequence == 0) ? 4 : status.sequence;
                    status.sequence = status.name == 'Canceled' && (status.sequence == null || status.sequence == 0) ? 5 : status.sequence;
                    this.statuses.push(Object.assign({}, status));
                }
                this.statuses.sort((a, b) => a.sequence - b.sequence);
                this.task.status.id = this.statuses.filter(status => status.name == 'Planned')[0].id;
            },
            err => {
                this.ttService.checkErr(err, 'error loading status', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading status')
        );
    }

    getTeams(companyId: number) {
        this.ttService.getTeamsByCompId(companyId).subscribe(
            data => {
                let response: any = data;
                for (let team of response) {
                    this.teams.push(Object.assign({}, team));
                }
            },
            err => {
                this.ttService.checkErr(err, 'error loading team', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading teams')
        );
    }

    getTaskById(taskId: number) {
        this.busy = this.ttService.getTaskById(taskId).subscribe(
            data => {
                this.fillTask(data);
            },
            err => {
                this.ttService.checkErr(err, 'error loading task', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading existing task')
        );
    }

    getPhasesByProject(projectId: number) {
        this.busy = this.ttService.getPhasesByProject(projectId).subscribe(
            data => {
                this.phases = data['phases'];
            },
            err => { },
            () => console.log('done loading phases by project')
        );
    }

    getPredecessorById(predecessorId: number) {
        this.ttService.getTaskById(predecessorId).subscribe(
            data => {
                let expectedStartDate = this.dateToDateObj(new Date(data['expectedStartDate']));
                let expectedFinishDate = this.dateToDateObj(new Date(data['expectedFinishDate']));
                this.predecessors.push({ 'id': data['id'], 'name': data['name'], 'expectedStart': expectedStartDate, 'expectedFinish': expectedFinishDate });
            },
            err => { },
            () => console.log('done loading predecessors')
        );
    }

    getPredecessorsByProject(companyId: number, projectId: number) {
        let specificationObj = {
            "project": [{ "id": projectId }],
            "company": { "id": companyId }
        };
        this.ttService.getPredecessorsBySpecification(specificationObj, this.taskId).subscribe(
            data => {
                for (let task of data['tasks']) {
                    if (task['id'] != this.taskId && (task['status'].name != 'Closed' && task['status'].name != 'Canceled')) {
                        task.expectedFinish = this.dateToDateObj(new Date(task.expectedFinishDate));
                        task.expectedStart = this.dateToDateObj(new Date(task.expectedStartDate));
                        this.predecessors.push(Object.assign({}, task));
                    }
                }
            },
            err => { },
            () => console.log('done loading predecessors by project')
        );
    }

    getCustomersByCompany(companyId: number) {
        this.busy = this.ttService.getCustomersForCompId(companyId).subscribe(
            data => {
                let response: any = data;
                for (let customer of response) {
                    customer.custId = customer.id;
                    customer.custName = customer.name;
                    this.customers.push(Object.assign({}, customer));
                }
            },
            err => {
                this.ttService.checkErr(err, 'error loading customer', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading customers by company')
        );
    }

    getProjectsByCustomer(companyId: number, customerId: number) {
        this.busy = this.ttService.getProjectsForCompIdCustId(companyId, customerId).subscribe(
            data => {
                let response: any = data;
                for (let project of response) {
                    if (project.statusName != 'Closed') this.projects.push(Object.assign({}, project));
                }
            },
            err => {
                this.ttService.checkErr(err, 'error loading project', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading projects by customer')
        );
    }

    getResponsiblesByTeam(teamId: number) {
        this.busy = this.ttService.getUserByTeamId(teamId).subscribe(
            data => {
                let response: any = data;
                for (let responsible of response) {
                    this.responsibles.push(Object.assign({}, responsible));
                }

                if (this.task.id) {
                    if (!this.responsibles.find(responsible => responsible.id == this.task.responsible.id)) {
                        this.responsibles.push(Object.assign({}, this.task.responsible));
                    }
                }
            },
            err => {
                this.ttService.checkErr(err, 'error loading customer', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done loading responsibles by team')
        );
    }

    fieldChanged(event: Event, field: string) {
        if (field === "Customer") {
            if (this.task.customer.custId != '') {
                this.projects = [];
                this.getProjectsByCustomer(+sessionStorage.getItem('compId'), +this.task.customer.custId);
                this.enableProject = true;
            } else {
                this.projects = [];
                this.task.project.id = '';
                this.enableProject = false;
                this.phases = [];
                this.task.phase.id = '';
                this.enablePhase = false;
                this.predecessors = [];
                this.task.predecessor.id = '';
                this.enablePredecessor = false;
            }
        }

        if (field === "Project") {
            if (this.task.project.id != '') {
                this.getPhasesByProject(+this.task.project.id);
                this.enablePhase = true;
                this.getPredecessorsByProject(+sessionStorage.getItem('compId'), +this.task.project.id);
                this.enablePredecessor = true;
            } else {
                this.phases = [];
                this.task.phase.id = '';
                this.enablePhase = false;
                this.predecessors = [];
                this.task.predecessor.id = '';
                this.enablePredecessor = false;
            }
        }

        if (field === "Team") {
            if (this.task.team.id != '') {
                this.responsibles = [];
                this.task.responsible.id = '';
                this.getResponsiblesByTeam(+this.task.team.id);
                this.enableResponsible = true;
            } else {
                this.responsibles = [];
                this.task.responsible.id = '';
                this.enableResponsible = false;
            }
        }

        if (field === "Predecessor Task") {
            if (this.task.predecessor.id != '') {
                let predecessor = this.predecessors.find(predecessor => predecessor.id == this.task.predecessor.id);
                let newExpectedStart = new Date(predecessor.expectedFinish.year, predecessor.expectedFinish.month - 1, predecessor.expectedFinish.day + 1);
                if (newExpectedStart.getDay() === 0) newExpectedStart.setDate(newExpectedStart.getDate() + 1);
                if (newExpectedStart.getDay() === 6) newExpectedStart.setDate(newExpectedStart.getDate() + 2);
                this.task.expectedStart = this.dateToDateObj(newExpectedStart);
                let interval = this.task.expectedStart.day + (Math.ceil(this.task.effort / 8) - 1);
                let finishDateObj = new Date(this.task.expectedStart.year, this.task.expectedStart.month - 1, this.task.expectedStart.day);
                finishDateObj.setDate(interval);
                this.task.expectedFinish = this.dateToDateObj(finishDateObj);
            }
        }
    }

    saveTask() {
        if (this.taskId) {
            this.updateTask();
        } else {
            this.createTask();
        }
    }

    createTask() {
        let invalidDates = new Array();

        if (!this.validateDates(this.task.expectedStart))
            invalidDates.push("\n- Task Expected Start: " + this.dateError);

        if (!this.validateDates(this.task.expectedFinish))
            invalidDates.push("\n- Task Expected Finish: " + this.dateError);

        if (!invalidDates.length) {
            let startDate = null;
            let finishDate = null;
            if (this.task.expectedFinish.year && this.task.expectedFinish.day && this.task.expectedFinish.month) {
                finishDate = new Date();
                finishDate.setFullYear(this.task.expectedFinish.year);
                finishDate.setDate(this.task.expectedFinish.day);
                finishDate.setMonth(this.task.expectedFinish.month - 1);
                this.task.expectedFinishDate = finishDate;
            }
            if (this.task.expectedStart.year && this.task.expectedStart.day && this.task.expectedStart.month) {
                startDate = new Date();
                startDate.setFullYear(this.task.expectedStart.year);
                startDate.setDate(this.task.expectedStart.day);
                startDate.setMonth(this.task.expectedStart.month - 1);
                this.task.expectedStartDate = startDate;
            }
            this.task.creationUser.id = sessionStorage.getItem("userId");
            this.task.company.id = +sessionStorage.getItem('compId');

            this.busy = this.ttService.createTask(this.task).subscribe(
                data => {
                    this.taskId = data['id'];
                    this.taskHistoryDescription = "Created Task " + this.taskId;
                    this.createTaskHistory('INSERT');
                    alert('Task #' + this.taskId + ' created!');
                    this.reason = 'node update';
                    this.close();
                },
                err => {
                    this.ttService.checkErr(err, 'error creating task', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                },
                () => console.log('done creating task')
            );
        } else {
            alert("The following field(s) is(are) invalid:" + invalidDates.join(""));
        }
    }

    updateTask() {
        let invalidDates = new Array();

        if (!this.validateDates(this.task.expectedStart))
            invalidDates.push("\n- Task Expected Start: " + this.dateError);

        if (!this.validateDates(this.task.expectedFinish))
            invalidDates.push("\n- Task Expected Finish: " + this.dateError);

        if (!invalidDates.length) {
            let startDate = null;
            let finishDate = null;
            if (this.task.expectedFinish.year && this.task.expectedFinish.day && this.task.expectedFinish.month) {
                finishDate = new Date();
                finishDate.setFullYear(this.task.expectedFinish.year);
                finishDate.setDate(this.task.expectedFinish.day);
                finishDate.setMonth(this.task.expectedFinish.month - 1);
                this.task.expectedFinishDate = finishDate;
            }
            if (this.task.expectedStart.year && this.task.expectedStart.day && this.task.expectedStart.month) {
                startDate = new Date();
                startDate.setFullYear(this.task.expectedStart.year);
                startDate.setDate(this.task.expectedStart.day);
                startDate.setMonth(this.task.expectedStart.month - 1);
                this.task.expectedStartDate = startDate;
            }
            this.task.updateUser = new User;
            this.task.updateUser.id = sessionStorage.getItem("userId");
            this.task.company.id = +sessionStorage.getItem('compId');

            this.busy = this.ttService.updateTask(this.task).subscribe(
                data => {
                    let description: string;

                    if (this.task.name != this.taskOld.name) {
                        description = "'Name' changed from [" + this.taskOld.name + "] to [" + this.task.name + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.status.id != this.taskOld.status.id) {
                        description = "'Status' changed from [" + this.taskOld.status.name + "] to [" + this.statuses.filter(status => status.id == this.task.status.id)[0].name + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.phase.id != this.taskOld.phase.id) {
                        description = "'Phase' changed from [" + this.taskOld.phase.name + "] to [" + this.phases.filter(phase => phase.id == this.task.phase.id)[0].name + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.description != this.taskOld.description) {
                        if (this.task.description.length >= 300 && this.taskOld.description.length <= 300) {
                            description = "'Description' changed from [" + this.taskOld.description.substring(0, 300) + "] to [" + this.task.description.substring(0, 300) + "...]";
                        }
                        if (this.task.description.length <= 300 && this.taskOld.description.length >= 300) {
                            description = "'Description' changed from [" + this.taskOld.description.substring(0, 300) + "...] to [" + this.task.description.substring(0, 300) + "]";
                        }
                        if (this.task.description.length >= 300 && this.taskOld.description.length >= 300) {
                            description = "'Description' changed from [" + this.taskOld.description.substring(0, 300) + "...] to [" + this.task.description.substring(0, 300) + "...]";
                        }
                        if (this.task.description.length <= 300 && this.taskOld.description.length <= 300) {
                            description = "'Description' changed from [" + this.taskOld.description.substring(0, 300) + "] to [" + this.task.description.substring(0, 300) + "]";
                        }
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.effort != this.taskOld.effort) {
                        description = "'Effort' changed from [" + this.taskOld.effort + "] to [" + this.task.effort + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.predecessor.id != this.taskOld.predecessor.id) {
                        description = "'Predecessor' changed from [" + this.taskOld.predecessor.name + "] to [" + this.predecessors.find(predecessor => predecessor.id == this.task.predecessor.id).name + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.expectedStart.day != this.taskOld.expectedStart.day ||
                        this.task.expectedStart.month != this.taskOld.expectedStart.month ||
                        this.task.expectedStart.year != this.taskOld.expectedStart.year) {
                        description = "'Expected Start' changed from [" + this.dateObjectToString(this.taskOld.expectedStart) + "] to [" + this.dateObjectToString(this.task.expectedStart) + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.expectedFinish.day != this.taskOld.expectedFinish.day ||
                        this.task.expectedFinish.month != this.taskOld.expectedFinish.month ||
                        this.task.expectedFinish.year != this.taskOld.expectedFinish.year) {
                        description = "'Expected Finish' changed from [" + this.dateObjectToString(this.taskOld.expectedFinish) + "] to [" + this.dateObjectToString(this.task.expectedFinish) + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.task.team.id != this.taskOld.team.id) {
                        description = "'Team' changed from [" + this.task.team.name + "] to [" + this.teams.filter(team => team.id == this.task.team.id)[0].name + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }
                    if (this.task.responsible.id != this.taskOld.responsible.id) {
                        description = "'Responsible' changed from [" + this.task.responsible.fullName + "] to [" + this.responsibles.filter(user => user.id == this.task.responsible.id)[0].fullName + "]";
                        this.taskHistoryDescription = this.taskHistoryDescription != "" ? this.taskHistoryDescription + ", " + description : this.taskHistoryDescription + description;
                    }

                    if (this.taskHistoryDescription != "") {
                        this.createTaskHistory('UPDATE');
                    }
                    alert('Task #' + data['id'] + ' saved.');
                    this.reason = 'node update';
                    this.close();
                },
                err => {
                    this.ttService.checkErr(err, 'error updating task', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                },
                () => console.log('done updating task')
            );
        } else {
            alert("The following field(s) is(are) invalid:" + invalidDates.join(""));
        }
    }

    deleteTask() {
        if (confirm('Are you sure you want to delete the task "' + this.task.name + '"?')) {
            this.busy = this.ttService.deleteTask(this.taskId).subscribe(
                data => {
                    alert("Task #" + data + " successfully deleted.");
                    this.reason = 'node update';
                    this.close();
                },
                err => {
                    alert(err.error.errorMessage);
                    this.ttService.checkErr(err, 'error deleting task', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                },
                () => console.log('done deleting task')
            );
        }
    }

    createTaskHistory(action) {
        this.busy = this.ttService.createTaskHistory({
            'action': action,
            'description': this.taskHistoryDescription
        }, sessionStorage.getItem('userId'), this.taskId).subscribe(
            response => {
                this.taskHistoryDescription = '';
                if (this.event) {
                    this.getTaskHistory(this.taskId, '?page=' + this.event.page + '&size=10');
                } else {
                    this.getTaskHistory(this.taskId, '?page=' + 0 + '&size=10');
                }
            },
            err => {
                this.ttService.checkErr(err, 'error loading task history', this.modalRef);
                this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
            },
            () => console.log('done creating task history')
        );
    }

    getTaskHistory(taskId: number, pagination: string) {
        this.ttService.getTaskHistoryPagination(taskId, pagination).subscribe(
            data => {
                let historyDate;
                this.taskHistories = data["taskHistory"];
                for (let history of this.taskHistories) {
                    history.userFullName = history.user.fullName;
                    history.taskName = history.task.name;

                    historyDate = new Date();
                    historyDate.setTime(history.dateCreated);

                    history.dateCreatedString = historyDate.getFullYear() + "-" + ("0" + (historyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + historyDate.getDate()).slice(-2) + " - " + ("0" + historyDate.getHours()).slice(-2) + ":" + ("0" + historyDate.getMinutes()).slice(-2);
                }

                this.totalPages = data["pageNumber"];
                this.totalElements = data["elementNumber"];
            });
    }

    redirect(page: string) {
        if (page == "ProgressByUsage") this.ttService.redirectToProgressByUsage(this.task.project.id, this.task.customer.custId);
        if (page == "TimeTrackingAnalytic") this.ttService.redirectToTimeTrackingAnalytic(this.task.project.id, this.task.customer.custId, this.taskId);
        this.close();
    }

    reopenTask() {
        let statusPlanned = this.statuses.filter(status => status.name == 'Planned')[0];
        this.task.status.id = statusPlanned.id;
        this.saveTask();
        this.taskHistoryDescription = "Task #" + this.taskId + " reopened by " + sessionStorage.getItem('currentUser')['fullName'] + ".";
        this.createTaskHistory('REOPEN');
    }

    show(template: TemplateRef<any>, row: TaskHistory) {
        if (row != null) {
            this.logTitle = row.id + " - " + row.action;
            this.populationViewLog(row);
            this.logModalRef = this.ngbModal.open(template);
        } else {
            this.generalModalRef = this.ngbModal.open(template);
        }
    }

    open(component: string, task: Task) {
        if (component == "TimeSheet") {
            let modalDialog = this.ngbModal.open(TimeSheetDialogComponent, {
                windowClass: 'sm-modal', backdrop: 'static', keyboard: false
            });

            modalDialog.result.then(response => {
                this.getProgress(this.taskId);
                this.reason = response === 'progress update' ? 'node update' : this.reason;
            });

            modalDialog.componentInstance.selectedClient = task.customer.custId;
            modalDialog.componentInstance.selectedProject = task.project.id;
            modalDialog.componentInstance.selectedMilestone = task.phase.id ? task.phase.id : null;
            modalDialog.componentInstance.selectedTask = this.taskId;
            modalDialog.componentInstance.disableEdit = true;
            modalDialog.componentInstance.type = 'Project';
        } else if (component == "Attachment") {
            let modal = this.ngbModal.open(AttachmentsDialogComponent, { size: 'lg' });
            modal.componentInstance.tableId = this.taskId;
            modal.componentInstance.tableName = 'task'
            modal.componentInstance.enableUpload = task.status.name != 'Closed' ? true : false;

            modal.result.then(response => {
                for (let fileLog in response) {
                    this.taskHistoryDescription = response[fileLog].description;
                    this.createTaskHistory(response[fileLog].action);
                }
                if (this.event) {
                    this.createTaskHistory(this.taskId + '?page=' + this.event.page + '&size=10');
                } else {
                    this.createTaskHistory(this.taskId + '?page=&size=10');
                }
            });
        }
    }

    populationViewLog(row) {
        this.logDescription = new Array();
        let positions = new Array();

        if (row.action == "UPDATE") {
            positions.push(parseInt(row.description.indexOf("'Name'"), 10));
            positions.push(parseInt(row.description.indexOf("'Customer'"), 10));
            positions.push(parseInt(row.description.indexOf("'Project'"), 10));
            positions.push(parseInt(row.description.indexOf("'Description'"), 10));
            positions.push(parseInt(row.description.indexOf("'Effort'"), 10));
            positions.push(parseInt(row.description.indexOf("'Parent Task'"), 10));
            positions.push(parseInt(row.description.indexOf("'Request Date'"), 10));
            positions.push(parseInt(row.description.indexOf("'Expected Start'"), 10));
            positions.push(parseInt(row.description.indexOf("'Expected Finish'"), 10));
            positions.push(parseInt(row.description.indexOf("'Date Deadline'"), 10));
            positions.push(parseInt(row.description.indexOf("'Team'"), 10));
            positions.push(parseInt(row.description.indexOf("'Responsible'"), 10));
            positions.push(parseInt(row.description.indexOf("'Requester E-mail'"), 10));
            positions.push(parseInt(row.description.indexOf("'Phase'"), 10));
            positions.push(parseInt(row.description.indexOf("'Category'"), 10));
            positions.push(parseInt(row.description.indexOf("'Status'"), 10));
            positions.push(parseInt(row.description.indexOf("'Root Cause'"), 10));
            positions.push(parseInt(row.description.indexOf("'Results'"), 10));
            positions.push(parseInt(row.description.indexOf("'Issues'"), 10));
            positions.push(parseInt(row.description.indexOf("'Decisions Pending'"), 10));
            positions.push(parseInt(row.description.indexOf("'Comment'"), 10));
            positions.push(parseInt(row.description.indexOf("'Complexity'"), 10));
            positions.push(parseInt(row.description.indexOf("'Predecessor'"), 10));
            positions.push(row.description.length);
            positions.sort((a, b) => a - b);

            for (let position in positions) {
                let aux = parseInt(position, 10);
                if (positions[aux] != -1 && aux != positions.length - 1) {
                    // The last vector it is not necessary to remove the commas
                    if (aux == positions.length - 2) {

                        // Business rule to show a maximum of 300 character
                        if ((positions[aux + 1] - positions[aux]) > 5000) {
                            this.logDescription.push(row.description.substring(positions[aux], positions[aux] + 300) + '...');
                        } else {
                            this.logDescription.push(row.description.substring(positions[aux], positions[aux + 1]));
                        }
                        // Remove the commas
                    } else {
                        // Business rule to show a maximum of 300 characters
                        if ((positions[aux + 1] - positions[aux]) > 5000) {
                            this.logDescription.push(row.description.substring(positions[aux], positions[aux] + 300) + '...');
                        } else {
                            this.logDescription.push(row.description.substring(positions[aux], positions[aux + 1] - 2));
                        }
                    }
                }
            }
        } else {
            this.logDescription.push(row.description);
        }
        let log = this.logDescription;
        for (let description of log) {
            if (description.includes("'Request Date'")) {
                let index = log.indexOf("'Request Date'");
                if (index > -1) {
                    delete log[index];
                    log[index] = this.extractData(description);
                }
            }
            if (description.includes("'Expected Start'")) {
                let index = log.indexOf("'Expected Start'");
                if (index > -1) {
                    delete log[index];
                    log[index] = this.extractData(description);
                }
            }
            if (description.includes("'Expected Finish'")) {
                let index = log.indexOf("'Expected Finish'");
                if (index > -1) {
                    delete log[index];
                    log[index] = this.extractData(description);
                }
            }
            if (description.includes("'Date Deadline'")) {
                let index = log.indexOf("'Date Deadline'");
                if (index > -1) {
                    delete log[index];
                    log[index] = this.extractData(description);
                }
            }
        }
        if (log.length > 0) this.logDescription = log;
    }

    extractData(description) {
        let left = description.split("[");
        let from = left[1].split("]")[0];
        let to = left[2].split("]")[0];
        if (from != null && from != '') {
            let temp = from.split("-");
            let fromDate = new Date(temp[0], temp[1], temp[2]);
            from = fromDate.getFullYear() + "-" + ("0" + (fromDate.getMonth())).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
        }
        if (to != null && to != '') {
            let temp = to.split("-");
            let toDate = new Date(temp[0], temp[1], temp[2]);
            to = toDate.getFullYear() + "-" + ("0" + (toDate.getMonth())).slice(-2) + "-" + ("0" + toDate.getDate()).slice(-2);
        }
        return left[0] + "[" + from + "] to [" + to + "]";
    }

    paginate(event) {
        //event.page = Index of the new page
        this.event = event;
        this.getTaskHistory(this.taskId, '?page=' + event.page + '&size=10');
    }

    fillTask(data: any) {
        this.task = Object.assign(this.task, data);
        //this.taskOld = new Task;

        this.task.predecessor = this.task.predecessor == null ? new PredecessorTask : this.task.predecessor;
        this.disableAll = this.task.status.name == 'Canceled' || this.task.status.name == 'Closed' ? true : false;
        this.allowTimesheet = this.task.status.name == 'In Progress' ? true : false;

        let expectedStartObj = new Date(data['expectedStartDate']);
        this.task.expectedStart = { year: expectedStartObj.getFullYear(), month: expectedStartObj.getMonth() + 1, day: expectedStartObj.getDate() };
        let expectedFinishObj = new Date(data['expectedFinishDate']);
        this.task.expectedFinish = { year: expectedFinishObj.getFullYear(), month: expectedFinishObj.getMonth() + 1, day: expectedFinishObj.getDate() };
        this.getProgress(this.task.id);
        this.getPredecessorById(+this.task.predecessor.id);

        if (this.event) {
            this.getTaskHistory(this.task.id, '?page=' + this.event.page + '&size=10');
        } else {
            this.getTaskHistory(this.task.id, '?page=' + 0 + '&size=10');
        }

        /*this.taskOld = Object.assign(this.taskOld, data);
        this.taskOld.predecessor = this.task.predecessor == null ? new PredecessorTask : this.taskOld.predecessor;
        this.taskOld.expectedStart = {year: expectedStartObj.getFullYear(), month: expectedStartObj.getMonth() + 1, day: expectedStartObj.getDate()};
        this.taskOld.expectedFinish = {year: expectedFinishObj.getFullYear(), month: expectedFinishObj.getMonth() + 1, day: expectedFinishObj.getDate()};*/

        this.taskOld = JSON.parse(JSON.stringify(this.task));
        this.taskOld.phase = this.taskOld.phase ? this.taskOld.phase : { 'id': '', 'name': '' };
        this.taskOld.predecessor = this.taskOld.predecessor ? this.taskOld.predecessor : { 'id': '', 'name': '', 'expectedStart': null, 'expectedFinish': null };

        this.customers = [this.task.customer];
        this.projects = [this.task.project];

        if (this.task.phase) {
            this.phases = [this.task.phase];
        } else {
            this.task.phase = new Phase;
            this.enablePhase = true;
            this.getPhasesByProject(+this.task.project.id);
        }
        this.getResponsiblesByTeam(+this.task.team.id);
        this.getPredecessorsByProject(+sessionStorage.getItem('compId'), +this.task.project.id);
    }

    getProgress(taskId: number) {
        this.ttService.getTaskProgress(taskId).subscribe(
            data => {
                let response: any = data;
                this.progress = Object.assign({}, response);
            }
        );
    }

    dateToDateObj(date: Date) {
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    dateObjectToString(date: { year: number, month: number, day: number }) {
        return date.year + "-" + ("0" + date.month).slice(-2) + "-" + ("0" + date.day).slice(-2);
    }

    close() {
        this.modalRef.close(this.reason);
    }

    validateDates(date) {
        //Parametros para validação.
        let regex = new RegExp('^([0-9]{4}-[0-9]{2}-[0-9]{2})$', 'g');
        let isValid = true;
        //Mensagens de erro.
        let invalidFormat = "Invalid date format."
        let invalidRange = "Invalid date range."
        this.dateError = null;
        //Caso a data seja inválida ela se tornará uma string.
        if (typeof date === "string") {
            //Caso a string tenha o formato correto, o erro é de range.
            if (regex.test(date)) {
                isValid = false;
                this.dateError = invalidRange;
                //Caso a string não tenha o formato correto, o erro é de formato.
            } else {
                isValid = false;
                this.dateError = invalidFormat;
            }
            //Caso a data seja do tipo date ela deverá ficar entre os anos de 1969 e 2099.
        } else if (date.year < 1969 || date.year > 2099) {
            //Se a data possuir 4 digitos, o erro é de range.
            if (date.year.toString().length == 4) {
                this.dateError = invalidRange;
                //Se a data possuir mais ou menos que 4 digitos, o erro é de formato.
            } else {
                this.dateError = invalidFormat;
            }
            isValid = false;
        }
        return isValid;
    }
}